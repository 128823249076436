import axios from "axios"
import { useState } from "react"
import { Modal } from "react-bootstrap"
import { BiEditAlt } from "react-icons/bi"
import { FiPhoneCall } from "react-icons/fi"
import { MdDelete } from "react-icons/md"
import useAuth from "../../../hooks/useAuth"
import useBaseServices from "../../../services/baseServices"
import useCompanyServices from "../../../services/companyServices"
import LeadEditForm from "../../leads/components/LeadEditForm"
import DeleteForm from "../../settings/components/DeleteForm"
import CustomerAddForm from "./CustomerAddForm"

const CustomerProfile = ({data, refresh, handleProfileClose, type}) => {
  const [showDelete, setShowDelete] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  
  const {auth} = useAuth()

  const { getStaffProfile } = useBaseServices()
  const { getCompanyProfile } = useCompanyServices()

  const handleClose = () => {
    setShowDelete(false)
    handleProfileClose()
  }

  const handleEditClose = () => {
    setShowEdit(false)
  }

  const getDate = ()=>{
    return new Date(data?.updated_at).toString().slice(0,25)
  }

  // const handleCall = async()=>{
  //   try{
  //       var mobile
  //       var response;
  //       if (auth.role === "company") {
  //         response = await getCompanyProfile()
  //       } else {
  //         response = await getStaffProfile()
  //       }
  //       if (response.success) {
  //         mobile = response?.data?.mobile
  //       }
  //     if (mobile){
  //       if (auth.role === "company"){
  //         response = await axios.get("https://pbx.voxbaysolutions.com/api/clicktocall.php?uid=Jhrt98ubnnHHkl3SsaZfytf&pin=lmakn76ghYTrBgfcx44&source=919072180795&destination=91"+data?.mobile.slice(-10)+"&ext=555&callerid=918069256458")
  //       }
  //       else{
  //         response = await axios.get("https://pbx.voxbaysolutions.com/api/clicktocall.php?uid=Jhrt98ubnnHHkl3SsaZfytf&pin=lmakn76ghYTrBgfcx44&source=91"+mobile.slice(-10)+"&destination=91"+data?.mobile.slice(-10)+"&ext=555&callerid=918069256458")
  //       }
  //     }
  //     // console.log(response)
  //   }catch(e){}
  // }

  return (
    <div className='col-12 document-card card'>
      <div className="customer-profile-card selected row py-4 pe-md-5 pe-2">
        {/* <div className="col-2 col-md-2 my-4 text-center p-md-1 pb-0">
          <FiPhoneCall size="60px" className="btn call-button" onClick={handleCall} />
        </div> */}
        <div className="col-12 col-md-7 my-3 ps-md-5 ps-5 pb-md-3 pb-0">
            <div className="customer-profile-name mb-2 row" >{data?.name}</div>
            <div className="mb-1 row" >{data?.email}</div>
            <div className="row" >Contact : {data?.mobile.slice(-10)}</div>
        </div>
        <div className="col-12 col-md-5 row my-md-3 mt-0 py-0 pe-0">
          <div className="col-12 col-md-12 position-relative d-flex justify-content-end pe-0">
            {(auth.role==='company')&&
              <div className="customer-profile-data profile-edit-card customer-icons px-2 px-md-0">
                <span className=" btn btn-danger" onClick={()=> setShowDelete(true)}><MdDelete size="18px" /></span>
              </div>}
              <div className="customer-profile-data end-0 customer-icons">
                <span className=" btn btn-primary" onClick={()=> setShowEdit(true)}><BiEditAlt size="18px" /></span>
              </div>
          </div>
          <div className="col-12 col-md-12 position-relative">
            <div className="customer-profile-data profile-date profile-common row" >Date : {getDate()}</div>
            <div className="customer-profile-data profile-common row bottom-0" >Alternate Number : {data?.alt_mobile||"Not Given"}</div>
          </div>
        </div>
      </div>
      <Modal
        show={showDelete}
        onHide={handleClose}
        centered={true}
        size="lg"
        contentClassName="agent-modal"
      >
        <Modal.Body className='p-5'>
          <DeleteForm data={data} refresh={refresh} type={type} handleClose={handleClose}/>
        </Modal.Body>
      </Modal>
      
      <Modal
        show={showEdit}
        onHide={handleEditClose}
        centered={true}
        size="lg"
        contentClassName="agent-modal"
      >
        <Modal.Body className='p-5'>
          {(type&&type==='Lead')?<LeadEditForm data={data} refresh={refresh} handleClose={handleEditClose} />:
          <CustomerAddForm data={data} refresh={refresh} handleClose={handleEditClose} />}
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default CustomerProfile