import axios from "../api/axios";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

//check if company exists or not
export const companyCheck = async () => {
    try {
        const response = await axios.get('/accounts/company_check/')
        return response.data
    }
    catch (err) {
        throw new Error("Company Already Exists")
    }
};

//register new company
export const companyRegister = async (data) => {
    try {
        const response = await axios.post('/accounts/company_register/', data,{
            headers: { 'Content-Type': 'multipart/form-data'},
        })
        return response.data

    } catch (err) {
        throw new Error("Company Registration fialed")
    }
};

//login api
export const login = async (data) => {
    try {
        const response = await axios.post('/accounts/login/', data)
        return response.data

    } catch (err) {
        throw new Error("Login fialed")
    }
};

//logout api
export const logout = async (data) => {
    try {
        const response = await axios.post('/accounts/logout/', data)
        return response.data

    } catch (err) {
        throw new Error("Login fialed")
    }
};

const useBaseServices = () => {
    const axiosPrivate = useAxiosPrivate()

    //get staff or agent details at first login
    const getProfileDetails = async() => {
        const response = await axiosPrivate.get("/accounts/set_first_password/")
        return response.data
    }
    
    //edit staff or agent details at first login
    const putProfileDetails = async(data) => {
        const response = await axiosPrivate.put("/accounts/set_first_password/", data)
        return response.data
    }

    //register new staff
    const postStaffRegister = async(data) => {
        const response = await axiosPrivate.post("/accounts/staff_register/", data,{
            headers:{'Content-Type': 'multipart/form-data'}
        })
        return response.data
    }

    //register new agent
    const postAgentRegister = async(data) => {
        const response = await axiosPrivate.post("/accounts/agent_register/", data,{
            headers: {'Content-Type':'multipart/form-data'}
        })
        return response.data
    }

    //change password
    const putPasswordChange = async(data) => {
        const response = await axiosPrivate.put("/accounts/change_password/", data)
        return response.data
    }

    //get staff or agent profile details
    const getStaffProfile = async() => {
        const response = await axiosPrivate.get("/accounts/profile/")
        return response.data
    }

    //edit staff or agent profile details
    const putStaffProfile = async(data) => {
        const response = await axiosPrivate.put("/accounts/profile/", data,{
            headers: { 'Content-Type': 'multipart/form-data'},
        })
        return response.data
    }

    //returns
    return{
        getProfileDetails,
        putProfileDetails,
        postStaffRegister,
        postAgentRegister,
        putPasswordChange,
        getStaffProfile,
        putStaffProfile
    }
}
export default useBaseServices