import { useEffect } from "react"
import { useState } from "react"
import { Form, Modal } from "react-bootstrap"
import { BsFillEyeFill } from 'react-icons/bs'
import { FiUpload } from 'react-icons/fi'
import Swal from "sweetalert2"
import { MEDIA_URL } from "../../../api/axios"
import useCustomerServices from "../../../services/customerServices"
import DocumentUploadForm from "./DocumentUploadForm"

const CustomerDocumentList = ({type, id, refresh}) => {
  const [status, setStatus] = useState("Not")
  const [show, setShow] = useState(false)
  const [documentList, setDocumentList] = useState("")

  useEffect(()=>{
    getDocumentList()
  },[])

  const { putChangeDocumentStatus, getAllDocuments } = useCustomerServices()

  const getDocumentList = async () => {
    const response = await getAllDocuments(id, type.name)
    if (response !== null) {
        // console.log(response?.data)
        setDocumentList(response.data)
        setStatus(response?.data?.status)
    }
  }

  const handleClose = () => setShow(false)

  const handleChange = async (e) => {
    e.preventDefault()
    setStatus(e.target.value)
    try {
      const datas = JSON.stringify({
        status:e.target.value
      });
      const response = await putChangeDocumentStatus(documentList?.id, datas)
      // console.log(response?.data)
      if (response.success) {
        Swal.fire("", "Document Status Changed Successfully", "success")
        getDocumentList()
        refresh()
      }
    }
    catch (err) {
      //console.log(err)
    }
  }
  return (
    <>
      <tr>
        <td className="ps-4 pt-3">{type.name}</td>
        <td className=" table-dropdown pt-3">
        <Form.Select
          className="status-dropdown mouse-pointer"
          size="md"
          value={status}
          onChange={(e)=>handleChange(e)}
        >
          <option className="menu" value="Not">Not</option>
          <option className="menu" value="Collected">Collected</option>
          <option className="menu" value="Done">Done</option>
        </Form.Select>
        </td>
        <td className=" pt-2">
          <div onClick={()=>setShow(true)} id="addLead" className="circular btn btn-primary">
            <FiUpload size="15px" /> Upload
          </div>
        </td>
        <td className=" pt-2">
          <div className="btn btn-success">
            <a href={MEDIA_URL+documentList?.document} className="text-light" target="_blank" rel="noreferrer"><BsFillEyeFill size="15px" /> View</a>
          </div>
        </td>
      </tr>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        contentClassName="upload-modal"
      >
        <Modal.Body className="p-4">
          <DocumentUploadForm handleClose={handleClose} id={id} doc={type.name} refresh={getDocumentList} />
        </Modal.Body>
      </Modal>
    </>
  )
}


export default CustomerDocumentList