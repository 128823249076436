import React from 'react'
import { useEffect, useState } from 'react'

import "./Lead.css"
import plus from "../../assets/images/icons/plus-circle-fill.png"

import {
  //  Dropdown, DropdownButton,
   Modal } from 'react-bootstrap'
// import { Search } from 'semantic-ui-react';
import LeadCard from "./components/LeadCard"
import LeadAddForm from './components/LeadAddForm'
import useCompanyServices from '../../services/companyServices'
import useAgentServices from '../../services/agentServices'
import useStaffServices from '../../services/staffServices'
// import useCustomerServices from '../../services/customerServices'
import useAuth from '../../hooks/useAuth'
// import { DatePicker } from '@mui/x-date-pickers'
// import { DateRangePicker } from '@mui/x-date-pickers-pro'
// import { format } from 'date-fns'
import Swal from 'sweetalert2'

const Leads = () => {
  const [show, setShow] = useState(false)
  // const [check, setCheck] = useState(false)
  const [leadList, setLeadList] = useState()
  const [leadName, setLeadName] = useState()
  const [leadEmail, setLeadEmail] = useState()
  const [leadMobile, setLeadMobile] = useState()
  const [leadLocation, setLeadLocation] = useState()
  const [leadSource, setLeadSource] = useState()
  const [leadInterest, setLeadInterest] = useState()
  // const [date, setDate] = useState("")
  // const [fromDate, setFromDate] = useState("")
  // const [toDate, setToDate] = useState("")

  // const [filter, setFilter] = useState("Filters")

  const { auth } = useAuth()

  const { getLeadListToday, postLeadListToday } = useCompanyServices()
  const { getAssignedLeadList } = useAgentServices()
  const { getLeadList } = useStaffServices()

  useEffect(() => {
    getLeadListData()
  }, [])

  const getLeadListData = async () => {
    var response = null
    if (auth?.role === 'company') {
      response = await getLeadListToday(
        // type, format(date, 'yyyy-MM-dd')
        )
    }
    else if (auth?.role === 'agent') {
      response = await getAssignedLeadList()
    }
    else {
      response = await getLeadList()
    }
    if (response?.success) {
      // console.log(response?.data?.leads)
      setLeadList(response?.data?.leads)
    }
  }

  // const handleSearch = () => {

  // }

  // const handleDate = async (e) => {
    
  //   setDate(e['$d'])
  //   getLeadListData("date")
  //   // console.log(e['$d'])

  // }

  // const handleFilter = async (e) => {
  //   e.preventDefault()
  //   // setDate(date[0])
  //   console.log(format(date, 'yyyy-MM-dd'))
  //   console.log(format(fromDate, 'yyyy-MM-dd'))
  //   console.log(format(toDate, 'yyyy-MM-dd'))
  // }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const data = JSON.stringify({
        name: leadName,
        email: leadEmail,
        mobile: leadMobile,
        location: leadLocation,
        source: leadSource,
        interest: leadInterest
      });
      const response = await postLeadListToday(data)
      //console.log(response?.data)
      getLeadListData()
      if (response?.success) {
        
        Swal.fire("", response?.message, "success")
        handleClose()
        
      }
      else{
        Swal.fire("", response?.message, "error")
      }
    }
    catch (err) {
      // console.log(err)
    }
  }
  const handleClose = () => setShow(false)
  return (
    <div className="">
      {/* <div className="row pe-1"> */}
        {/* <div id="leadsearch" className="row z-index-n1 pe-1"> */}
          {/* {auth?.role === "company" &&
            <div id="searchLead" className="col-3 me-0 pe-1 my-3">
              <div className="me-0">
              <Search
                input={{ icon: 'search', iconPosition: 'left', fluid: true }}
                className="lead-searchBox"
                placeholder="Search Here..."
                size='large'
                fluid={true}

                // onResultSelect={}
                onSearchChange={handleSearch}
              />
              </div>
            </div>} */}
          {/* <div className="col-md-2 my-3">
            <DropdownButton id="dropdown-basic-button" title={filter}>
              <Dropdown.Item onClick={() => setFilter("Today")}>Today</Dropdown.Item>
              <Dropdown.Item onClick={() => setFilter("Yesterday")}>Yesterday</Dropdown.Item>
              <Dropdown.Item onClick={() => setFilter("Specific day")}>Specific day</Dropdown.Item>
              <Dropdown.Item onClick={() => setFilter("Date Range")}>Date Range</Dropdown.Item>
            </DropdownButton>
          </div> */}
          {/* {filter === "Specific day" &&
          <>
            <div className="col-md-2 my-3 ps-1">
              <DatePicker
                className='lead-datepicker'
                onChange={(e)=>handleDate(e)}
                format="DD-MMM-YYYY"
              />
            </div>
          </>} */}
          {/* {filter === "Date Range" && 
          <>
            <div className="col-md-2 my-3 ps-1">
              <DatePicker
                className='lead-datepicker col-12'
                onChange={(e)=>setFromDate(e['$d'])}
                format="DD-MMM-YYYY"
              />
            </div>
            <div className="col-md-3 my-3 ps-1">
              <DatePicker
                className='lead-datepicker col-8 pe-2'
                onChange={(e)=>setToDate(e['$d'])}
                format="DD-MMM-YYYY"
              />
              <div className='btn btn-pill btn-outline-dark col-4' onClick={(e)=>handleFilter(e)}>search</div>
            </div>
            
          </>
          } */}
        {/* </div> */}
      {/* </div> */}
      <div className="row mt-3 ms-1 mb-3">
        <div className="col-lg-12">
        {window.innerWidth>768&&<div className="lead-title-card col-12 row p-3 pe-0">
            <div className="col-1 ps-2 pe-0 p-2 lead-card-head serial-number">Sl.<br />no</div>
            <div className="col-1 p-2 lead-card-head px-0">Name</div>
            <div className="col-1 lead-card-head p-2 pe-0 ps-2">Phone number</div>
            <div className="col-3 lead-card-head p-2 ps-5">E-mail</div>
            <div className="col-1 lead-card-head p-2 ps-0">Created</div>
            <div className="col-1 lead-card-head p-2 ps-0">Updated</div>
            <div className="col-1 lead-card-head p-2 px-0">Interested</div>
            <div className="col-1 lead-card-head p-2 px-0">Location</div>
            {auth?.role === "company" || auth?.role === "agent" ?
              <>
                <div className="col-1 lead-card-head p-2 pe-0 ps-0">Assign</div>
                <div className="col-1 lead-card-head p-2 pe-5 text-center">Status</div>
              </>
              :
              <>
                <div className="col-1 lead-card-head p-2 px-0">Status</div>
                <div className="col-1 lead-card-head p-2 pe-5 text-center">Desc</div>
              </>
            }
          </div>}
        </div>
      </div>
      <div className="row lead-container">
        {leadList && leadList.map(
          (data, index) => {
            return (
              <div key={index} className="row lead-data ms-1 me-2 ">
                <LeadCard auth={auth} index={index + 1} data={data} updateLeads={getLeadListData} />
              </div>
            )
          }
        )
        }
      </div>
      {/* <div className='row'>
        <div className='col-md-5'>
          
        </div>
        <div className='col-md-1 p-3' hidden>
          <i className='btn btn-outline-primary'><Icon name='arrow left' size='big' /></i>
        </div>
        <div className='col-md p-3'>
          <span className='btn btn-primary'><span className='next-page'>Next </span><Icon name='arrow right' size='big' className='' /></span>
        </div>
      </div> */}
      {auth.role === "company" &&
        <div className=" ">
          <div onClick={() => setShow(true)} className="button position-fixed bottom-0 end-0">
            <img src={plus} className=" lead-plus-button" alt="" />

          </div>
          <Modal
            show={show}
            onHide={handleClose}
            centered={true}
            size="lg"
            contentClassName="lead-modal"
          >
            <Modal.Body className='p-5'>
              <LeadAddForm
                handleSubmit={handleSubmit}
                handleClose={handleClose}
                setLeadName={setLeadName}
                setLeadEmail={setLeadEmail}
                setLeadMobile={setLeadMobile}
                setLeadLocation={setLeadLocation}
                setLeadInterest={setLeadInterest}
                setLeadSource={setLeadSource}
              />
            </Modal.Body>
          </Modal>
        </div>
      }
    </div>
  )
}

export default Leads