import React from 'react'
import { useEffect, useState } from 'react'

import "./FollowUps.css"

import FollowUpCard from './components/FollowUpCard'
import useCompanyServices from '../../services/companyServices'
import useLeadServices from '../../services/leadServices'
import useAuth from '../../hooks/useAuth'

const FollowUps = () => {
  const [followupList, setFollowUpList] = useState()

  const { auth } = useAuth()

  const { getFollowUpList} = useCompanyServices()
  const { getStaffsFollowUpList } = useLeadServices()

  useEffect(() => {
    getFollowUpListData()
  }, [])

  const getFollowUpListData = async () => {
    var response = null
    if (auth?.role === 'company'){
      response = await getFollowUpList()
    }
    else{
      response = await getStaffsFollowUpList()
    }
    if (response !== null) {
      //console.log(response?.data)
      setFollowUpList(response.data.leads)
    }
  }

  // const handleSearch = () => {}
  return (
    <div className="">
      <div className="row mt-3 mb-3 me-0">
        <div className="col-lg-12">
          {window.innerWidth>768&&<div className="followup-title-card col-12 row p-3 pe-0">
            <div className="col-1 ps-2 pe-0 p-2 followup-card-head serial-number">Sl.<br />no</div>
            <div className="col-1 p-2 followup-card-head px-0">Name</div>
            <div className="col-1 followup-card-head p-2 pe-5 ps-3">Phone number</div>
            <div className="col-3 followup-card-head p-2 ps-5">E-mail</div>
            <div className="col-1 followup-card-head p-2 px-0">Course</div>
            <div className="col-1 followup-card-head p-2 px-0">Location</div>
            <div className="col-1 followup-card-head p-2 px-2">Updated</div>
            {auth?.role === "company"||auth.role === "agent" ?
              <>
                <div className="col-1 followup-card-head p-2 ps-1 pe-5">Created</div>
                {auth.role==="company"&&<div className="col-1 followup-card-head p-2 ps-0 pe-0">Assign</div>}
                {auth.role==="agent"&&<div className="col-1 followup-card-head p-2 ps-0">Follow Up</div>}
              </>
              :
              <>
              <div className="col-1 followup-card-head p-2 ps-2">Follow Up</div>
              <div className="col-1 followup-card-head p-2 ps-1">Status</div>
              </>
            }
            {auth?.role === "company" ? <div className="col-1 followup-card-head p-2 ps-2 pe-0">Status</div>
            :<div className="col-1 followup-card-head p-2 pe-4 text-center">Desc</div>}
          </div>}
        </div>
      </div>
      <div className="followup-container">
        {followupList && followupList.map(
          (data, index) => {
            return (
              <div key={index} className="row followup-data me-0">
                <FollowUpCard auth={auth} index={index + 1} data={data} updateFollowUps={()=>getFollowUpListData()} />
              </div>
            )
          }
        )
        }
      </div>
    </div>
  )
}

export default FollowUps