import React, { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa'
import Swal from 'sweetalert2';
import useLeadServices from '../../../services/leadServices';
import TableData from './TableData';



const FieldSettings = () => {
  const [createModal, setCreateModal] = useState(false)
  const [fieldName, setFieldName] = useState("")
  const [fieldType, setFieldType] = useState("Character")
  const [fieldTypeList, setFieldTypeList] = useState("")

  const { postCreateFieldType, getAllFieldTypes } = useLeadServices()

  useEffect(() => {
    getFieldTypeList()
  }, [])


  const getFieldTypeList = async () => {
    let response = await getAllFieldTypes()
    if (response.success) {
      setFieldTypeList(response.data)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    
    try {console.log(fieldType)
      if (fieldType) {
        const data = JSON.stringify({
          "name":fieldName,
          "type":fieldType
        })
        
        let response = await postCreateFieldType(data)
        if (response.success) {
          Swal.fire("", "Field Type Created Successfully", "success")
          setFieldName('')
          setFieldType('Character')
          handleCreateClose()
          getFieldTypeList()
        } else {
          Swal.fire("", response?.message, "error")

        }
      }


    } catch (err) {
      console.log("error")
    }

  }

  const handleCreateOpen = () => setCreateModal(true);

  const handleCreateClose = () => setCreateModal(false);
  return (
    <>
      <div className="row position-relative mb-5 ms-4">
        <button onClick={handleCreateOpen} className='btn col-12 col-md-2 position-absolute me-3 btn-pill end-0 btn-success pill '>
          <FaPlus size="14px" /> Add new
        </button>
        <Modal

          show={createModal}
          onHide={handleCreateClose}
          centered={true}
          size="md"
          contentClassName=""
        >
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <div className='p-2'>
                <h3>Create Field Type</h3>
                <div className="row my-3">
                    <div className="col-md-7 px-1 col-12">
                        <Form.Group className='' controlId='staffName'>
                            <Form.Label className=''>Name</Form.Label>
                            <Form.Control value={fieldName} onChange={(e) => setFieldName(e.target.value)} className='py-2' placeholder="Eg: Gender" />
                        </Form.Group>
                    </div>
                    <div className="col-md-5 px-1 col-12">
                        <Form.Group className='' controlId=''>
                            <Form.Label className=''>Type</Form.Label>
                            <Form.Select
                              className={"mouse-pointer type-dropdown "
                              // + window.innerWidth<768&&"mobile-view-dropdown "
                            }
                              size="md"
                              value={fieldType}
                              onChange={(e)=>setFieldType(e.target.value)}
                              >
                              <option className="menu" value="Character">Character</option>
                              <option className="menu" value="Number">Number</option>
                              <option className="menu" value="Email">Email</option>
                              <option className="menu" value="TextArea">TextArea</option>
                              <option className="menu" value="File">File</option>
                              <option className="menu" value="Image">Image</option>          
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className='col-12 px-2'>
                      <Form.Text className='text-muted'><b>Note : </b>For phone numbers please select Character type</Form.Text>
                    </div>
                </div>
                <div className="position-relative mb-5">
                  <div className='position-absolute end-0 row'>
                    <div onClick={handleCreateClose} className='col btn btn-danger mx-2'>Cancel</div>
                    <button onClick={(e)=>handleSubmit(e)} className='col btn btn-primary'>Submit</button>
                  </div>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
      <div className="row my-3">
        <div className="settings-table-container">
          <div>
            {window.innerWidth>768&&
              <div className="row p-2 pe-4 settings-table-header">
                <div className="col-1 fs-5">Sl.No</div>
                <div className="col-5 fs-5">Field Name</div>
                <div className="col-3 fs-5">Field Type</div>
                <div className="col-1 fs-5 ps-3">Edit</div>
                <div className="col-1 fs-5">Active</div>
                <div className="col-1 text-center pe-5 ps-3 fs-5">Delete</div>
              </div>
            }
          </div>
          <div className="my-2 settings-table-body">
            {fieldTypeList && fieldTypeList.map((data, index) =>
              <TableData data={data} refresh={getFieldTypeList} type={"Field Type"} index={index} key={index}/>
            )}

          </div>
        </div>
      </div>
    </>
  )
}

export default FieldSettings