import './Agents.css'
import { Icon } from 'semantic-ui-react'
import AgentCard from './components/AgentCard'
import { Modal } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import add_img from "../../assets/images/icons/add_img.png"
import AddUserForm from '../../components/forms/adduserform/AddUserForm'
import AssignLeadForm from './components/AssignLeadForm'
import useBaseServices from '../../services/baseServices'
import useCompanyServices from '../../services/companyServices'
import { MEDIA_URL } from '../../api/axios'
import useAuth from '../../hooks/useAuth'
import { Navigate } from 'react-router-dom'
import AgentStaffTable from './components/AgentStaffTable'


const Agents = () => {
  //modal handlers
  const [addModal, setAddModal] = useState(false)
  const [assignModal, setAssignModal] = useState(false)
  const [assignStaffModal, setAssignStaffModal] = useState(false);
  //agent data
  const [agentList, setAgentList] = useState("")
  const [agentName, setAgentName] = useState("")
  const [agentPost, setAgentPost] = useState("")
  const [agentPhone, setAgentPhone] = useState("")
  const [agentEmail, setAgentEmail] = useState("")

  const [currentAgent, setCurrentAgent] = useState("")
  const [fkUser, setFkUser] = useState("")

  //lists
  const [leadList, setLeadList] = useState("")
  const [staffList, setStaffList] = useState("")

  //image
  const [imagePreview, setImagePreview] = useState(add_img)
  const [selected, setSelected] = useState("")
  // const [imgUrl, setImageUrl] = useState("")

  const [edit, setEdit] = useState()


  const { auth } = useAuth()

  //services
  const { postAgentRegister } = useBaseServices()
  const { putAgentDetailsToday } = useCompanyServices()
  const { getAgentListToday, getAgentAssignLead, getStaffAssignAgent } = useCompanyServices()


  //useEffects
  useEffect(() => {
    getAgentList();
  }, [])

  useEffect(() => {
    if (!selected) {
      setImagePreview(add_img)
      return
    }

    const objectUrl = URL.createObjectURL(selected)
    setImagePreview(objectUrl)

    return () => URL.revokeObjectURL(objectUrl)
  }, [selected])




  //api functions

  const getAgentList = async (e) => {
    try {
      const response = await getAgentListToday()
      // console.log(response)
      if (response?.success) {
        setAgentList(response?.data)
        // console.log(response.data)
      }
    } catch (err) { console.log(err) }

  }

  const getStaffList = async (id, choice) => {
    try {
      const response = await getStaffAssignAgent(currentAgent||id, choice)
      if (response.success) {
        setStaffList(response.data)
      }
    } catch (err) { }
  }

  const getLeadList = async (id, choice) => {
    try {
      const response = await getAgentAssignLead(currentAgent||id, choice)
      if (response.success) {
        // console.log(response?.data)
        setLeadList(response.data)
      }
    } catch (err) { console.log(err) }
  }


  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const data = new FormData();
      if (edit) {
        data.append("first_name", agentName)

      } else {
        data.append("name", agentName)
      }
      if (selected) {
        data.append("image", selected)
      }
      data.append("mobile", agentPhone)
      data.append("email", agentEmail)
      data.append("post", agentPost)

      var response
      if (edit) {
        response = await putAgentDetailsToday(currentAgent, data)
      }
      else {
        response = await postAgentRegister(data)
      }
      if (response.success) {
        setAddModal(false);
        handleClear()
        getAgentList()

      }


    } catch (e) { console.error(e) }
  }




  //states
  const handleClear = () => {
    setAgentName("")
    setAgentEmail("")
    setAgentPhone("")
    setAgentPost("")
    setSelected("")
    setImagePreview("")
  }

  const handleOpenAssignModal = (data) => {
    setAssignModal(true)
    setCurrentAgent(data.id)
    setFkUser(data.fkUser)
    getLeadList(data.id)
  }

  const handleOpenAssignStaffModal = (data) => {
    setAssignStaffModal(true)
    setCurrentAgent(data.id)
    setFkUser(data.fkUser)
    getStaffList(data.id)
  }

  const handleCloseAddModal = () => {
    setAddModal(false)
  }
  const handleOpenAddModal = (e, data) => {
    if (!data) {
      handleClear()
    }
    setAddModal(true)
  }

  const handleCloseAssignModal = () => {
    setAssignModal(false)
    getAgentList()
  }

  const handleCloseAssignStaffModal = () => {
    setAssignStaffModal(false)
    getAgentList()
  }


  const handleImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelected(undefined)
      return
    }

    setSelected(e.target.files[0])
  }
  const handleEdit = (data) => {
    setAgentName(data.first_name)
    setAgentEmail(data.email)
    setAgentPhone(data.mobile)
    setAgentPost(data.post)
    setImagePreview(MEDIA_URL + data.image)
    // setImageUrl(data.image)
    setEdit(true)
    setCurrentAgent(data.id)
    handleOpenAddModal("", true)
  }

  return (
    auth.role === "company" ?
      <div className="">
        <div className="row">
          <div id="agent-header-btn" className="row">
            {/* <div className="agent-cal btn button text-bg-primary me-2 px-1">
                        <Icon name="calendar alternate outline" size='large' />
                    </div> */}
            <div onClick={handleOpenAddModal} className="agent-cal ui circular button text-bg-primary">
              <Icon name="add user" />
              Add Agents
            </div>
            <Modal
              show={addModal}
              onHide={handleCloseAddModal}
              centered={true}
              size="lg"
              contentClassName="agent-modal"
            >
              <Modal.Body className='p-5'>
                <AddUserForm
                  title="Add agent Member"
                  admin={true}
                  {...{
                    imagePreview, handleImage,
                    selected, handleSubmit,
                    agentName, setAgentName,
                    agentPost, setAgentPost,
                    agentPhone, setAgentPhone,
                    agentEmail, setAgentEmail
                  }} handleClose={handleCloseAddModal} />
              </Modal.Body>
            </Modal>
          </div>
        </div>
        <div className="row">
          {agentList && agentList.map((data, index) =>
            <AgentCard key={index} refresh={getAgentList} assignStaff={handleOpenAssignStaffModal} open={handleOpenAssignModal} edit={handleEdit} user={data.first_name} img={MEDIA_URL + data.image} role={data.post} data={data} />)}


        </div>
        <Modal
          show={assignModal}
          onHide={handleCloseAssignModal}
          centered={true}
          size="lg"
          contentClassName="agent-modal"
        >
          <Modal.Body className='p-5'>
            <AssignLeadForm leadList={leadList} fkUser={fkUser} getLeadList={getLeadList} currentAgent={currentAgent} close={handleCloseAssignModal} title="Assign Leads" />
          </Modal.Body>
        </Modal>
        <Modal
          show={assignStaffModal}
          onHide={handleCloseAssignStaffModal}
          centered={true}
          size="lg"
          contentClassName="agent-modal"
        >
          <Modal.Body className='p-5'>
            <AgentStaffTable currentAgent={currentAgent} staffList={staffList} fkUser={fkUser} getStaffList={getStaffList} close={handleCloseAssignStaffModal} title="Assign Staff" />
          </Modal.Body>
        </Modal>
      </div>
      :
      <Navigate to="/" />
  )
}

export default Agents