import './Settings.css'
import { Navigate } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import { Modal, Nav, Tab } from 'react-bootstrap'
import { useState } from 'react'
import StatusSettings from './components/StatusSettings'
import DocumentSettings from './components/DocumentSettings'
import InterestSettings from './components/InterestSettings'
import FieldSettings from './components/FieldSettings'

const Settings = () => {
    const [createModal, setCreateModal] = useState(false)
    const [viewModal, setViewModal] = useState(false)

    const { auth } = useAuth()


    const handleCreateOpen = () => setCreateModal(true);
    const handleViewOpen = () => setViewModal(true);

    const handleCreateClose = () => setCreateModal(false);
    const [key, setKey] = useState("tab1");

    const handleViewClose = () => setViewModal(false);
    return (
        auth.role === "company" ?
            <div className='ms-3 ms-md-0'>
                <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
                    <div className="row  me-2">
                        <div className="col-md-3">
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item className='my-2'>
                                    <Nav.Link className='py-3' eventKey="tab1">Customer Status Settings</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='my-2'>
                                    <Nav.Link className='py-3' eventKey="tab2">Document Settings</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='my-2'>
                                    <Nav.Link className='py-3' eventKey="tab3">Product Settings</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='my-2'>
                                    <Nav.Link className='py-3' eventKey="tab4">Field Settings</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className="col-md-9 settings-card p-3">
                            <Tab.Content>
                                <Tab.Pane eventKey="tab1">
                                    <div>
                                        <StatusSettings/>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tab2">
                                    <div>
                                        <DocumentSettings/>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tab3">
                                    <div>
                                        <InterestSettings/>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tab4">
                                    <div>
                                        <FieldSettings/>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </div>
                </Tab.Container>
            </div>
            // return (
            //         <div className="row me-3">
            //             <div className="settings-card">
            //                 <div className=" m-3 mb-4 py-3 ps-3">
            //                     <div className='h4'>Status Settings</div>
            //                     <div className="mt-4">
            //                         <button onClick={handleCreateOpen} className='btn  btn-success col-12 col-md-2 fs-5'>Create</button>
            //                     </div>
            //                     <div className="mt-4">
            //                         <button onClick={handleViewOpen} className='btn  btn-primary col-12 col-md-2 fs-5 text-start'>Show status list</button>
            //                     </div>
            //                 </div>
            //                 <hr />
            //                 <div className=" m-3 py-3 ps-3 mt-5">
            //                     <div className='h4'>Document Types Settings</div>
            //                     <div className="mt-4">
            //                         <button onClick={handleCreateOpen} className='btn  btn-success col-12 col-md-2 fs-5'>Create</button>
            //                     </div>
            //                     <div className="mt-4">
            //                         <button onClick={handleViewOpen} className='btn  btn-primary fs-5 col-12 col-md-2 text-start'>Show type list</button>
            //                     </div>
            //                 </div>
            //             </div>
            //             <Modal
            //                 show={createModal}
            //                 onHide={handleCreateClose}
            //                 centered={true}
            //                 size="lg"
            //                 contentClassName=""
            //             >
            //                 <Modal.Body>

            //                 </Modal.Body>
            //             </Modal>
            //             <Modal
            //                 show={viewModal}
            //                 onHide={handleViewClose}
            //                 centered={true}
            //                 size="lg"
            //                 contentClassName=""
            //             >
            //                 <Modal.Body>

            //                 </Modal.Body>

            //             </Modal>
            //         </div>
            :
            <Navigate to="/" />
    )
}

export default Settings

