import React from 'react'
import { Form } from 'react-bootstrap'

const StaffAddForm = (props) => {
    const { imagePreview, handleImage, selected, handleSubmit,handleClose } = props
    const {name,setName,
        post,setPost,
        mobile,setMobile,
        email,setEmail}=props
    return (
        <div className="">
            <div className="row">
                <div className="h3 fw-bolder">Add Staff</div>
            </div>
            <Form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-4">
                        <div className=' p-3'>
                            <label htmlFor="uploadStaffImg" className='staff-img col-12'>

                                <img src={imagePreview}
                                //  className={selected != "" ? "w-100" : ""}
                                  alt="" />

                            </label>
                            <input id='uploadStaffImg' onChange={(e) => handleImage(e)} hidden className='form-control' type="file" accept='image/*' />
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="row my-4">
                            <div className="col-md-7 px-1">
                                <Form.Group className='' controlId='staffName'>
                                    <Form.Label className='fw-bolder'>Name</Form.Label>
                                    <Form.Control value={name}onChange={(e)=>setName(e.target.value)} className='py-2' placeholder="Beserac" />
                                </Form.Group>
                            </div>
                            <div className="col-md-5 px-1">
                                <Form.Group className='' controlId='staffPost'>
                                    <Form.Label className='fw-bolder'>Post</Form.Label>
                                    <Form.Control value={post}onChange={(e)=>setPost(e.target.value)} className='py-2' placeholder="BDE" />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className="col-5 px-1">
                                <Form.Group className='' controlId='staffPhone'>
                                    <Form.Label className='fw-bolder'>Phone number</Form.Label>
                                    <Form.Control value={mobile}onChange={(e)=>setMobile(e.target.value)} className='py-2' placeholder="+91 9876543210" />
                                </Form.Group>
                            </div>
                            <div className="col-7 px-1">
                                <Form.Group className='' controlId='staffPost'>
                                    <Form.Label className='fw-bolder'>Email</Form.Label>
                                    <Form.Control value={email}onChange={(e)=>setEmail(e.target.value)} className='py-2' placeholder="user@example.com" />
                                </Form.Group>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row staff-btn-container">
                    <div className="row col-md-6">
                        <div className="col-md-6">
                        <button type='button' onClick={handleClose} className='btn btn-outline-primary staff-btn col-12'>Cancel</button>
                        </div>
                        <div className="col-md-6">
                            <button type='submit' className='btn btn-primary staff-btn col-12'>Save</button>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default StaffAddForm