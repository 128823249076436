
import { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Navigate } from 'react-router-dom';
import { Popup, Dropdown, Search, Icon } from 'semantic-ui-react';
import useAuth from '../../../hooks/useAuth';

import useReportServices from '../../../services/reportServices';
import { DropdownButton } from 'react-bootstrap';
import { DatePicker } from '@mui/x-date-pickers';


const LeadReport = () => {
  const [leadList, setLeadList] = useState()

  const [months, setMonths] = useState()
  const [closed, setClosed] = useState()
  const [open, setOpen] = useState()
  const [unavailable, setUnavailable] = useState()
  const [paused, setPaused] = useState()
  const [rejected, setRejected] = useState()
  const [total, setTotal] = useState()

  const [filter, setFilter] = useState("Filters")
  
  const {auth} = useAuth()
  
  const {getReportLeadList, getReportLeadChart} = useReportServices()
  
  useEffect(()=>{
    getLeadList(auth.role)
    getLeadChart(auth.role)
  },[]);

  const getLeadList = async (user) => {
    const response = await getReportLeadList(user)
    // console.log(response?.data?.leads)
    setLeadList(response.data.leads)
  }
  const getLeadChart = async (user) => {
    const response = await getReportLeadChart(user)
    // console.log(response?.data)
    // setLeadChart(response.data)
    var data = response.data
    setMonths(data['months'])
    setClosed(data['closed'])
    setOpen(data['open'])
    setRejected(data['rejected'])
    setUnavailable(data['unavailable'])
    setPaused(data['paused'])
    setTotal(data['total'])
  }
  
  const handleSearch = () => {

  }


  //dropdown

  // const dropDownItems = [
  //   { key: 1, text: 'All', value: 1 },
  //   { key: 2, text: 'Closed ', value: 2 },
  //   { key: 3, text: 'Rejected', value: 3 },
  //   { key: 4, text: 'Unavailable', value: 4 },
  //   { key: 5, text: 'Paused', value: 5 },
  //   { key: 6, text: 'Open', value: 6 },
  // ]


  //chart
  const lineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels:{
          boxWidth:15,
          padding:30,
          useBorderRadius:true,
          borderRadius:2,
          font: {
            size:15,
          },
        },
        title:{
          display:false,
          font:{
            size:78
          }
        },
      },
      
      tooltip: {
        backgroundColor: '#3E66CD',
        yAlign: 'bottom',
        boxWidth: 20,
        padding: 10,
        bodySpacing: 10
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
          drawTicks: false, //hide datatype lines
        },
        beginAtZero: false,
        ticks: {
          display: false //hide the datatype values
        }
      },
    },
  };
  const lineLabels = months;
  const lineData = {
    labels: lineLabels,
    datasets: [
      {
        label: 'Closed',
        data: closed,
        borderColor: '#35D40D',
        borderWidth:1.5,
        backgroundColor: '#35D40D',
        tension: 0.5
      },
      {
        label: 'Rejected',
        data: rejected,
        borderColor: '#D31C1C',
        borderWidth:1.5,
        backgroundColor: '#D31C1C',
        tension: 0.5
      },
      {
        label: 'Unavailable',
        data: unavailable,
        borderColor: '#DEC910',
        borderWidth:1.5,
        backgroundColor: '#DEC910',
        tension: 0.5
      },
      {
        label: 'Paused',
        data: paused,
        borderColor: '#9745FF',
        borderWidth:1.5,
        backgroundColor: '#9745FF',
        tension: 0.5
      },
      {
        label: 'Opened',
        data: open,
        borderColor: '#25B4D3',
        borderWidth:1.5,
        backgroundColor: '#25B4D3',
        tension: 0.5
      },
      {
        label: 'New',
        data: total,
        borderColor: '#000000',
        borderWidth:1.5,
        backgroundColor: '#000000',
        tension: 0.5
      },
    ],
  };
    return (
      auth.role === 'company' || auth.role === 'agent'?
        <div className=" ps-0">
          <div className="row px-0 mb-3">
            <div className="report-pane-staff p-4 pe-3 ">
              <div className="row mb-3">
                <div className="col-md-8 report-staff-chart-title">
                  Monthly Leads
                </div>

              </div>
              <div className="row p-3 pe-4">
                <div className="col-md-12 report-pane-chart ">
                  <div className='row p-4'style={{height:350,}}>
                  <Line data={lineData} options={lineOptions} /></div>
                </div>
                {/* <div className='col-md-1'></div> */}
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="report-pane-staff p-4">
              <div className="row">
                <div className="report-staff-chart-title col-md-6">Monthly Lead List</div>
                {/* <div className="row pe-1">
        <div id="leadsearch" className="row z-index-n1 pe-1"> */}
          {/* {auth?.role === "company" &&
            <div id="searchLead" className="col-3 me-0 pe-1 my-3">
              <div className="me-0">
              <Search
                input={{ icon: 'search', iconPosition: 'left', fluid: true }}
                className="lead-searchBox"
                placeholder="Search Here..."
                size='large'
                fluid={true}

                onResultSelect={}
                onSearchChange={handleSearch}
              />
              </div>
            </div>} */}
          {/* <div className="col-md-2 my-3">
            <DropdownButton id="dropdown-basic-button" title={filter}>
              <Dropdown.Item onClick={() => setFilter("Status")}>Status</Dropdown.Item>
              <Dropdown.Item onClick={() => setFilter("Followup")}>Followup</Dropdown.Item> */}
              {/* <Dropdown.Item onClick={() => setFilter("Specific day")}>Specific day</Dropdown.Item>
              <Dropdown.Item onClick={() => setFilter("Date Range")}>Date Range</Dropdown.Item> */}
            {/* </DropdownButton>
          </div> */}
          {/* {filter === "Specific day" && <div className="col-md-2 my-3">
            <DatePicker
              className='lead-datepicker col-12'
            />
          </div>} */}
          {/* {filter === "Date Range" && 
          <><div className="col-md-2 my-3">
          <DatePicker
            className='lead-datepicker col-12'
          />
        </div><div className="col-md-2 my-3">
            <DatePicker
              className='lead-datepicker col-12'
            />
          </div></>
          } */}
        {/* </div>
      </div> */}
                {/* <div className="col-md-3">
                  <Search className='report-staff-chart-sub'
                    input={{ icon: 'search', iconPosition: 'left', fluid: true }}
                    placeholder="Search Here..."
                    // onResultSelect={}
                    size='mini'
                    onSearchChange={handleSearch}

                  />
                </div>
                <div className="col-md-3">
                  <Dropdown
                    className='report-staff-drop report-staff-chart-sub'
                    selection
                    fluid={true}
                    placeholder="Select status"
                    options={dropDownItems} />
                </div> */}
              </div>
              <div className="row p-3">
                <div className="report-pane-table p-3 ">
                  <div className="table-responsive">
                    <table className="table table-striped ">
                      <thead>
                        <tr className='bg-dark'>
                          <td>Sl no.</td>
                          <td>Name</td>
                          <td>Email</td>
                          <td>Phone number</td>
                          <td>Interested</td>
                          <td>Followup Stage</td>
                          <td>Status</td>
                          <td align='center'>Description</td>
                        </tr>
                      </thead>
                      <tbody>
                        {leadList && leadList.map(
                          (data, index) => {
                            return (
                              auth.role === 'company'?
                                // data.status != 'New'?
                                <tr className='status-container ' key={index}>
                                <td>{index + 1}</td>
                                <td>{data.name}</td>
                                <td>{data.email}</td>
                                <td>{data.mobile}</td>
                                <td>{data.interest}</td>
                                <td>{data.follow_up}</td>
                                <td><div className={' '+ data.status}>{data.status}</div></td>
                                <td align='center' className='text-primary'>
                                  <Popup
                                    trigger={<Icon size="large" name='file alternate' />}
                                    position='bottom right'
                                    size='mini'
                                  >
                                    <Popup.Header>Description</Popup.Header>
                                    <Popup.Content>{data.description}
                                    </Popup.Content>
                                  </Popup>
                                  </td>
                                </tr>
                                // :<></>
                              :
                                // data.lead.status != 'New'?
                                <tr className='status-container ' key={index}>
                                  <td>{index + 1}</td>
                                  <td>{data.lead.name}</td>
                                  <td>{data.lead.email}</td>
                                  <td>{data.lead.mobile}</td>
                                  <td>{data.lead.interest}</td>
                                  <td>{data.lead.follow_up}</td>
                                  <td><div className={' '+ data.lead.status}>{data.lead.status}</div></td>
                                  <td align='center' className='text-primary'>
                                  <Popup
                                    trigger={<Icon size="large" name='file alternate' />}
                                    position='bottom right'
                                    size='mini'
                                  >
                                    <Popup.Header>Description</Popup.Header>
                                    <Popup.Content>{data.lead.description}
                                    </Popup.Content>
                                  </Popup>
                                  </td>
                                </tr>
                                // :<></>
                            )
                          }
                        )}
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      :
      <Navigate to="/"/>
  )
}
export default LeadReport