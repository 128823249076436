
import { Dropdown, Icon, Search } from 'semantic-ui-react';
import './Header.css';
// import profile_icon from '../../assets/images/logos/profile_logo.png';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { logout } from '../../services/baseServices';


const Header = ({ toggle }) => {

    const location = useLocation();
    const navigate = useNavigate()
    const { setAuth, auth } = useAuth()

    const trigger = (
        <div className='bg'><img src={auth?.image} className='profile-img' alt="" /></div>)
    // const options = [

    //     { key: 'profile', text: 'Profile' },
    //     { key: 'stars', text: 'Log out' },]

    function getHeading() {
        switch (location.pathname) {
            case '/':
                return 'Dashboard';
            case '/staff':
                return 'Staff';
            case '/leadlist':
                return 'Lead list';
            case '/followups':
                return 'Followups list';
            case '/agents':
                return 'Agents';
            case '/reports':
                return 'Reports';
            case '/profile':
                return 'Profile';
            case '/customer':
                return 'Customer';
            case '/settings':
                return 'Settings';
            default:
                return 'Navbar';
        }
    }

    const handleItemClick = (event, data) => {
        event.preventDefault();
        event.stopPropagation();
    };


    const handleSearch = () => {

    }

    const handleNavigateToProfile = () => navigate("/profile")
    const handleLogout = async () => {
        try {
            const data = ""
            const response = await logout(data)

            if (response.success) {
                localStorage.setItem("userName", "")
                localStorage.setItem("password", "")
                localStorage.setItem("accessToken", "")
                localStorage.setItem("role", "")
                localStorage.setItem("image", "")
                setAuth("")
                navigate("/login")
            }
        } catch (err) {

        }
    }
    return (
        <nav className={auth.role==="staff"?"navbar navbar-light bg-light lower": "navbar navbar-light bg-light "}>
            <div className="container-fluid">

                <div className='h3 nav-header mouse-pointer px-4'><span onClick={toggle} className="">
                    <Icon name='bars' size='large' />
                </span>&nbsp;&nbsp;{getHeading()}</div>
                <div className="nav-right">
                    {window.innerWidth > 768 && <>
                        <div className="me-3">
                            <Search
                                input={{ icon: 'search', iconPosition: 'left' }}
                                placeholder="Search Here..."
                                // onResultSelect={}
                                onSearchChange={handleSearch}

                            />
                        </div><div className='me-3'>
                            <Icon name="bell" size='large' />
                        </div>
                        {auth.role==="company"&&<div onClick={()=>navigate("/settings")} className="me-3 mouse-pointer">
                            <Icon name="setting" size='large' />
                        </div>}
                        </>}
                    <Dropdown trigger={trigger} className=""
                        direction="left"

                        onClick={handleItemClick}>
                        <Dropdown.Menu className='profile-drop-down mt-2 p-3'>
                            {window.innerWidth < 768 && <>
                                <Dropdown.Item>
                                    <div className="me-3">
                                        <Search
                                            input={{ icon: 'search', iconPosition: 'left' }}
                                            placeholder="Search Here..."
                                            // onResultSelect={}
                                            onSearchChange={handleSearch}
                                            onClick={e => e.stopPropagation()}

                                        />
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item text="Notification" value="1" icon="bell"

                                />
                                {auth.role==="company"&&<Dropdown.Item text="Settings" onClick={()=>navigate("/settings")} value="2" icon="setting"

                                />}
                            </>}
                            <Dropdown.Item text="Profile" value="3" icon="user"
                                onClick={handleNavigateToProfile}
                            />
                            <Dropdown.Item text="Logout" value="4" icon="sign-out"
                                onClick={handleLogout}
                            />
                        </Dropdown.Menu>
                    </Dropdown>

                    {/* <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                    <button className="btn btn-outline-success" >Search</button> */}
                </div>
            </div>
        </nav>
        // <div className="navbar navbar-expand-lg navbar-light bg-light">
        // <div className=" ">
        //     {/* <a className="navbar-brand" href="#">Navbar</a> */}
        //     <button onClick={()=>setCollapse((data)=>data?false:true)} className="btn" type="button">
        //         <span className="">☰</span>
        //     </button>

        // </div>
        // </div>
    )
}

export default Header