import useAxiosPrivate from "../hooks/useAxiosPrivate";

const useLeadServices = () => {
    const axiosPrivate = useAxiosPrivate()

    //get lead list
    const getLeadBulkUpload = async () => {
        const response = await axiosPrivate.get("/lead/bulkupload/")
        return response.data
    }

    //post bulk lead file
    const postLeadBulkUpload = async(data) => {
        const response = await axiosPrivate.post("/lead/bulkupload/", data,{ 
            headers:{"Content-Type":"multipart/form-data"}
        })
        return response.data
    }

    //change lead status and details
    const putLeadStatusChange = async (id, data) => {
        const response = await axiosPrivate.put("/lead/lead_status_change/" + id + "/", data)
        return response.data
    }

    //get leads assigned to agent in company page,
    //get leads assigned to staff in agent page
    const getAdminAssignedLeadList = async (id) => {
        var response
        if (id) {
             response = await axiosPrivate.get("/lead/assigned_lead_list/" + id + "/")
             const list = response.data.data.map((data)=>data.lead)
            //  console.log(list)
             response.data.data=list
        } else {
             response = await axiosPrivate.get("/lead/assigned_lead_list/")
        }
        return response.data
    }

    //get followup list for agent or staff
    const getStaffsFollowUpList = async () => {
        const response = await axiosPrivate.get("/lead/follow_up_list/")
        return response.data
    }

    //get all field types 
    const getAllFieldTypes = async() => {
        const response = await axiosPrivate.get("/lead/field_type/create/")
        return response.data
    }

    //get all active field types 
    const getActiveFieldTypes = async() => {
        const response = await axiosPrivate.get("/lead/field_type/active/")
        return response.data
    }

    //create field types
    const postCreateFieldType = async(data) => {
        const response = await axiosPrivate.post("/lead/field_type/create/", data)
        return response.data
    }

    //get field types details
    const getFieldTypeDetails = async(id) => {
        const response = await axiosPrivate.get("/lead/field_type/edit/"+id+"/")
        return response.data
    }

    //edit field types details
    const putFieldTypeDetails = async(id, data) => {
        const response = await axiosPrivate.put("/lead/field_type/edit/"+id+"/", data)
        return response.data
    }

    //delete field types
    const deleteFieldType = async(id) => {
        const response = await axiosPrivate.delete("/lead/field_type/edit/"+id+"/")
        return response.data
    }

    //change field types status
    const putFieldTypeStatus = async(id, data) => {
        const response = await axiosPrivate.put("/lead/field_type/status/"+id+"/", data)
        return response.data
    }

    //get all lead field Contents
    const getAllFieldContents = async(id, name) => {
        const response = await axiosPrivate.get("/lead/field_data/create/"+id+"/", {
            params: { "name": name }
        })
        return response.data
    }

    //add field content with lead id
    const postFieldContent = async(id, data) => {
        const response = await axiosPrivate.post("/lead/field_data/create/"+id+"/", data,{ 
            headers:{"Content-Type":"multipart/form-data"}
        })
        return response.data
    }

    //get field data
    const getFieldContent = async(id) => {
        const response = await axiosPrivate.get("/lead/field_data/edit/"+id+"/")
        return response.data
    }

    //edit field data field id
    const putFieldContent = async(id, data) => {
        const response = await axiosPrivate.put("/lead/field_data/edit/"+id+"/", data,{ 
            headers:{"Content-Type":"multipart/form-data"}
        })
        return response.data
    }

    //delete field
    const deleteFieldContent = async(id) => {
        const response = await axiosPrivate.delete("/lead/field_data/edit/"+id+"/")
        return response.data
    }

    //returns
    return {
        //get services
        getLeadBulkUpload,
        getAdminAssignedLeadList,
        getStaffsFollowUpList,
        getAllFieldTypes,
        getActiveFieldTypes,
        getFieldTypeDetails,
        getAllFieldContents,
        getFieldContent,

        //post services
        postLeadBulkUpload,
        postCreateFieldType,
        postFieldContent,

        //put services
        putLeadStatusChange,
        putFieldTypeDetails,
        putFieldTypeStatus,
        putFieldContent,

        //delete services
        deleteFieldType,
        deleteFieldContent,
        
    }
}
export default useLeadServices