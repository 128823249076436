import React, { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa'
import useCustomerServices from '../../../services/customerServices';
import Swal from 'sweetalert2';
import TableData from './TableData';



const InterestSettings = () => {
  const [createModal, setCreateModal] = useState(false)
  const [interest, setInterest] = useState("")
  const [interestList, setInterestList] = useState("")

  const { postCreateInterest, getAllInterests } = useCustomerServices()

  useEffect(() => {
    getInterestList()
  }, [])


  const getInterestList = async () => {
    let response = await getAllInterests()
    // console.log(response?.data)
    if (response.success) {
      setInterestList(response.data)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      if (interest) {
        const data = JSON.stringify({
          "name": interest
        })
        let response = await postCreateInterest(data)
        if (response.success) {
          Swal.fire("", "Interest Created Successfully", "success")
          setInterest('')
          handleCreateClose()
          getInterestList()
        } else {
          Swal.fire("", "Some Errors occured", "error")

        }
      }


    } catch (err) {

    }

  }

  const handleCreateOpen = () => setCreateModal(true);

  const handleCreateClose = () => setCreateModal(false);
  return (
    <>
      <div className="row position-relative mb-5 ms-4">
        <button onClick={handleCreateOpen} className='btn col-12 col-md-2 position-absolute me-3 btn-pill end-0 btn-success pill '>
          <FaPlus size="14px" /> Add new
        </button>
        <Modal

          show={createModal}
          onHide={handleCreateClose}
          centered={true}
          size="md"
          contentClassName=""
        >
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <div className='p-2'>
                <h3>Create Product</h3>
                <div className=" my-3">
                  <Form.Group className='' controlId='staffName'>
                    <Form.Label className=''>Product Name</Form.Label>
                    <Form.Control value={interest} onChange={(e) => setInterest(e.target.value)} className='py-2' placeholder="Eg: visa" />
                  </Form.Group>
                </div>
                <div className="position-relative mb-5">
                  <div className='position-absolute end-0 row'>
                    <div onClick={handleCreateClose} className='col btn btn-danger mx-2'>Cancel</div>
                    <button className='col btn btn-primary'>Submit</button>
                  </div>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
      <div className="row my-3">
        <div className="settings-table-container">
          <div>
            {window.innerWidth>768&&
              <div className="row p-2 pe-4 settings-table-header">
                <div className="col-1 fs-5">Sl.No</div>
                <div className="col-8 fs-5">Product</div>
                <div className="col-1 fs-5 ps-3">Edit</div>
                <div className="col-1 fs-5">Active</div>
                <div className="col-1 text-center pe-5 ps-3 fs-5">Delete</div>
              </div>
            }
          </div>
          <div className="my-2 settings-table-body">
            {interestList && interestList.map(
              (data, index) => { 
                return(
                  <TableData data={data} refresh={getInterestList} type={"Interest"} index={index} key={index}/>
                )
              }
            )}

          </div>
        </div>
      </div>
    </>
  )
}

export default InterestSettings