import React, { useState } from 'react'
import { useEffect } from 'react'
import { DatePicker } from '@mui/x-date-pickers'
import useCustomerServices from '../../../services/customerServices'
import Swal from 'sweetalert2'
import { Form } from 'react-bootstrap'

function ProductAddForm({refresh, handleClose, id}) {
    const [interestList, setInterestList] = useState("")
    const [interest, setInterest] = useState("")
    const [date, setDate] = useState("")
    const [month, setMonth] = useState("")
    const [year, setYear] = useState("")
    // const { auth } = useAuth()

    const { getActiveInterests, postApplyInterest } = useCustomerServices()

    useEffect(() => {
        getInterestList()
    }, [])
    
    const getInterestList = async () => {
        const response = await getActiveInterests()
        if (response !== null) {
            setInterestList(response.data)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {        
            const datas = JSON.stringify({
                interest:interest,
                date:date,
                month:month,
                year:year
            });
            const response = await postApplyInterest(id, datas)
            // console.log(response?.data)
            if (response.success) {
                Swal.fire("", "Product Added Successfully", "success")
                setDate("")
                setMonth("")
                setYear("")
                refresh()
                handleClose()
            }
            else{
                Swal.fire("", response?.message, "error")
            }
            
        }
        catch (err) {
        //   console.log(err)
        }
    }

    return (
        <div className="">
            <div className="row">
                <div className="h3 fw-bolder">Add Product</div>
            </div>
            <Form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row my-2 p-2">
                            <div className="col-md-5 p-2">
                                <Form.Group className='table-dropdown row' controlId='leadName'>
                                    <Form.Label className='fw-bolder col-12'>Product</Form.Label>
                                    <div className='col-12'>
                                        <Form.Select
                                            className="interest-dropdown mouse-pointer form-select pt-2"
                                            size="lg"
                                            onChange={(e)=>setInterest(e.target.value)}
                                        >
                                            <option className="menu" value="">Select</option>
                                            {interestList && interestList.map(
                                                (data, index) => {
                                                return(
                                                    <option key={index} className="menu" value={data.name}>{data.name}</option>
                                                )
                                                }
                                            )}
                                        </Form.Select>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-7 p-2">
                                <Form.Group className='row'>
                                    <Form.Label className='fw-bolder col-12'>Session Start</Form.Label>
                                    <div className='col-12'>
                                        <DatePicker
                                            label={'"day"'}
                                            views={['day']}
                                            className="col-4 pe-1 session-datepicker"
                                            onChange={(e)=>setDate(e["$D"])}
                                            format="DD"
                                        />
                                        <DatePicker
                                            label={'"month"'}
                                            views={['month']}
                                            className="col-4 pe-1 session-datepicker"
                                            onChange={(e)=>setMonth(e["$M"]+1)}
                                            format="MMM"
                                        />
                                        <DatePicker
                                            label={'"year"'}
                                            views={['year']}
                                            className="col-4 pe-1 session-datepicker"
                                            onChange={(e)=>setYear(e["$y"])}
                                            format="YYYY"
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row staff-btn-container">
                    <div className="col-md-6"></div>
                    <div className='col-md-6'>
                        <div className='row'>
                            <div className="col-md-6 pb-2">
                                <div onClick={handleClose} className='btn btn-outline-primary staff-btn col-12'>Cancel</div>
                            </div>
                            <div className="col-md-6">
                                <button type='submit' className='btn btn-primary staff-btn col-12'>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default ProductAddForm