import { useEffect } from "react"
import { useState } from "react"
import { Form, Modal } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { Popup, Icon } from "semantic-ui-react"
import Swal from "sweetalert2"
import useLeadServices from "../../../services/leadServices"
import CustomerProfile from "../../customer/components/CustomerProfile"
import LeadFieldList from "../../leads/components/LeadFieldList"

const FollowUpCard = ({ index, data, auth, updateFollowUps, }) => {
    const [descModal, setDescModal] = useState(false)
    const [showProfile, setShowProfile] = useState(false)
    const { putLeadStatusChange, getActiveFieldTypes } = useLeadServices()
    const navigate = useNavigate()
    //console.log(1)    

    const [id, setId] = useState(data?.id)
    const [name, setName] = useState(data?.name)
    const [mobile, setMobile] = useState(data?.mobile)
    const [mail, setMail] = useState(data?.mail)
    const [course, setCourse] = useState(data?.interest)
    const [location, setLocation] = useState(data?.location)
    const [assign, setAssign] = useState(data?.is_assigned ? "Assigned" : "Unassigned")
    const [description, setDescription] = useState(data?.description)
    const [followUp, setFollowUp] = useState(data?.follow_up)
    const [status, setStatus] = useState(data?.status)
    const [fieldTypeList, setFieldTypeList] = useState("")

    const loadData = () => {
        setId(data.id)
        setName(data.name)
        setMobile(data.mobile)
        setMail(data.email)
        setCourse(data.interest)
        setLocation(data.location)
        setAssign(data.is_assigned ? "Assigned" : "Unassigned")
        setFollowUp(data.follow_up)
        setStatus(data.status)
        setDescription(data.description)
    }
    useEffect(() => {
        loadData()
        getFieldTypeList()
    }, [])

    const getFieldTypeList = async () => {
        const response = await getActiveFieldTypes()
        if (response !== null) {
            setFieldTypeList(response.data)
        }
    }

    const handleCloseDesc = () => setDescModal(false);

    const handleSubmit = async (key, e) => {
        e && e.preventDefault()
        var datas = null
        // console.log(status)
        if (key === "follow") {
            setFollowUp(e.target.value)
            datas = JSON.stringify({
                'follow_up': e.target.value
            });
        }
        else if (key === "status") {
            setStatus(e.target.value)
            datas = JSON.stringify({
                'status': e.target.value
            });
        }
        else if (key === "description") {
            datas = JSON.stringify({
                'description': e.target.value
            });
        }
        const response = await putLeadStatusChange(id, data = datas)
        if (response?.success) {
            if (e.target.value === 'Closed') {
                navigate('/leadlist')
            }
            handleCloseDesc()
            Swal.fire("", "Lead updated", "success")
            updateFollowUps()
        }
        else {
            Swal.fire("", "Lead not updated !!!", "error")
        }
    }

    const getDate = (data) => {
        return new Date(data).toString().slice(4, 15)
    }
    const handleClose = () => setShowProfile(false)

    return (
        <div className="col-lg-12">
            <Modal
                size="lg"
                centered
                show={descModal}
                onHide={handleCloseDesc}
                contentClassName="followup-desc-modal px-5"
            >
                <Modal.Body>
                    <Form onSubmit={(e) => handleSubmit("description", e)}>
                        <div className="row mb-3 my-3">
                            <div className="h3 fw-bold">Add Description</div>
                        </div>
                        <div className="row mb-3">
                            <Form.Control className="followup-desc-area p-4" as="textarea" placeholder="enter followup status description" value={description} onChange={(e) => setDescription(e.target.value)} />
                        </div>
                        <div className="row mb-3">
                            <div className="float-end">
                                <button className="btn btn-primary btn-pill float-end col-4">Save</button>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            {window.innerWidth > 768 ?
                <div className="followup-card col-12 row p-3 pe-0 ">
                    <div className="col-1 ps-3 pe-0 p-1 my-2 followup-card-title">{index}</div>
                    <div className="col-1 my-2 followup-card-title followup-data p-1 px-0 overflow-hidden mouse-pointer text-primary" onClick={(e) => setShowProfile(true)}>{name}</div>
                    <div className="col-1 my-2 followup-card-title followup-data p-1 pe-5 ps-3 ">{mobile}</div>
                    <div className="col-3 my-2 followup-card-title followup-data p-1 ps-5 overflow-hidden">{mail}</div>
                    <div className="col-1 my-2 followup-card-title followup-data p-1 px-0 overflow-hidden">{course}</div>
                    <div className="col-1 my-2 followup-card-title followup-data p-1 px-0 overflow-hidden">{location}</div>
                    <div className="col-1 my-2 followup-card-title followup-data p-1 px-2 overflow-hidden">{getDate(data?.updated_at)}</div>

                    {auth.role === "company" || auth.role === "agent" ?
                        <>
                            <div className="col-1 my-2 followup-card-title followup-data p-1 px-0 overflow-hidden">{getDate(data?.created_at)}</div>
                            {auth.role === "company" &&
                                <div className="col-1 my-2 assign-followup p-1 px-0">
                                    <div className={" " + assign}>{assign}</div>
                                </div>
                            }
                            <div className="col-1 my-2 p-2 followup-status-container pe-4 ps-0">
                                <div className={"followup-status text-center col-12 " + followUp}>{followUp}</div>
                            </div>
                            {auth.role === "agent" &&
                                <div className="col-1 my-2  ps-2 text-primary text-center pe-4">
                                    <Popup
                                        trigger={<Icon onClick={() => setDescModal(true)} size="large" name='file alternate' />}
                                        position='bottom right'
                                        size='mini'
                                    >
                                        <Popup.Header>Description</Popup.Header>
                                        <Popup.Content>{description}
                                        </Popup.Content>
                                    </Popup>

                                </div>
                            }
                        </>
                        :
                        <>
                            <div className="col-1 my-2 followup-card-title pe-0 ps-2">
                                {/* <div className={" " + assign}> */}
                                <div>
                                    <Form.Select
                                        value={followUp}
                                        onChange={(e) => (handleSubmit("follow", e))}
                                        className="followup-dropdown"
                                        size="sm"
                                    >
                                        <option className="menu" value="Lead">Lead</option>
                                        <option className="menu" value="First">First</option>
                                        <option className="menu" value="Second">Second</option>
                                        <option className="menu" value="Third">Third</option>
                                    </Form.Select>
                                </div>
                                {/* </div> */}
                            </div>
                            <div className="col-1 my-2 followup-card-title pe-0 ps-1">
                                <div className="">
                                    <Form.Select
                                        value={status}
                                        onChange={(e) => (handleSubmit("status", e))}
                                        className="status-dropdown"
                                        size="sm"
                                    >
                                        <option className="menu" value="New">New</option>
                                        <option className="menu" value="Open">Open</option>
                                        <option className="menu" value="Closed">Closed</option>
                                        <option className="menu" value="Paused">Paused</option>
                                        <option className="menu" value="Rejected">Rejected</option>
                                        <option className="menu" value="Unavailable">Unavailable</option>
                                    </Form.Select>
                                </div>
                            </div>
                            <div className="col-1 my-2  ps-2 text-primary text-center pe-4">
                                <Popup
                                    trigger={<Icon onClick={() => setDescModal(true)} size="large" name='file alternate' />}
                                    position='bottom right'
                                    size='mini'
                                >
                                    <Popup.Header>Description</Popup.Header>
                                    <Popup.Content>{description}
                                    </Popup.Content>
                                </Popup>

                            </div>
                        </>
                    }
                </div>
                :
                <div className="followup-card responsive col-12 p-3 pe-0 my-2">
                    <table className=" my-2 followup-card-title followup-data p-1 pe-5 ps-3 ">
                        <tr>
                            <th>Name</th>
                            <td>
                                <div className=" overflow-auto mouse-pointer text-primary" onClick={(e) => setShowProfile(true)}>{data?.name}</div>
                        </td>
                    </tr>
                    <tr>
                        <th>Mobile</th>
                        <td>{mobile}</td>
                    </tr>
                    <tr>
                        <th>Mail</th>
                        <td>{mail}</td>
                    </tr>
                    <tr>
                        <th>Course</th>
                        <td>{course}</td>
                    </tr>
                    <tr>
                        <th>Location</th>
                        <td>{location}</td>
                    </tr>
                    <tr>
                        <th>Updated On</th>
                        <td>{getDate(data?.updated_at)}</td>
                    </tr>
                    {auth.role === "company" || auth.role === "agent" ?
                        <>
                            <tr>
                                <th>Created On</th>
                                <td>{getDate(data?.created_at)}</td>
                            </tr>
                            {auth.role === "company" && <tr>
                                <th>Assigned To</th>
                                <td>
                                    <div className=" assign-followup p-1 px-0">
                                        <div className={" " + assign}>{assign}</div>
                                    </div>

                                </td>
                            </tr>}
                            <tr>
                                <th>Status</th>
                                <td>
                                    <div className=" p-2 followup-status-container pe-4 ps-0">
                                        <div className={"followup-status text-center col-12 " + followUp}>{followUp}</div>
                                    </div>
                                </td>
                            </tr>
                            {auth.role === "agent" &&
                                <tr>
                                    <th>Desc</th>
                                    <td>
                                        <div className=" my-2  ps-2 text-primary text-center pe-4">
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                <Form.Control className="col-12" readOnly as="textarea" value={description} onClick={() => setDescModal(true)} rows="2" />
                                            </Form.Group>
                                        </div>
                                    </td>
                                </tr>}
                        </>
                        :
                        <>
                            <tr>
                                <th>Follow Up</th>
                                <td>
                                    <div>
                                        <Form.Select
                                            value={followUp}
                                            onChange={(e) => (handleSubmit("follow", e))}
                                            className="followup-dropdown"
                                            size="sm"
                                        >
                                            <option className="menu" value="Lead">Lead</option>
                                            <option className="menu" value="First">First</option>
                                            <option className="menu" value="Second">Second</option>
                                            <option className="menu" value="Third">Third</option>
                                        </Form.Select>
                                    </div></td>
                            </tr>
                            <tr>
                                <th>Status</th>
                                <td>
                                    <div className="">
                                        <Form.Select
                                            value={status}
                                            onChange={(e) => (handleSubmit("status", e))}
                                            className="status-dropdown"
                                            size="sm"
                                        >
                                            <option className="menu" value="New">New</option>
                                            <option className="menu" value="Open">Open</option>
                                            <option className="menu" value="Closed">Closed</option>
                                            <option className="menu" value="Paused">Paused</option>
                                            <option className="menu" value="Rejected">Rejected</option>
                                            <option className="menu" value="Unavailable">Unavailable</option>
                                        </Form.Select>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>Desc</th>
                                <td>
                                    <div className=" my-2 text-primary text-center pe-4">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Control className="col-12" readOnly as="textarea" value={description} onClick={() => setDescModal(true)} rows="2" />
                                        </Form.Group>
                                    </div>
                                </td>
                            </tr>
                        </>
                    }
                </table>
                </div>
            }
            <Modal
                size="lg"
                centered
                show={descModal}
                onHide={handleCloseDesc}
                contentClassName="followup-desc-modal px-5"
            >
                <Modal.Body>
                    <Form onSubmit={(e) => handleSubmit("description", e)}>
                        <div className="row mb-3 my-3">
                            <div className="h3 fw-bold">Add Description</div>
                        </div>
                        <div className="row mb-3">
                            <Form.Control className="followup-desc-area p-4" as="textarea" placeholder="enter followup status description" value={description} onChange={(e) => setDescription(e.target.value)} />
                        </div>
                        <div className="row mb-3">
                            <div className="float-end">
                                <button className="btn btn-primary btn-pill float-end col-4">Save</button>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal
                show={showProfile}
                onHide={handleClose}
                centered={true}
                size="xl"
                contentClassName="lead-modal px-md-4 py-2 p-4"
                >
                <Modal.Body className=''>
                    <div className='card document-card row'>
                        <CustomerProfile data={data} refresh={updateFollowUps} handleProfileClose={handleClose} type={"Lead"} />
                        <div className='col-12'>
                            <div className="row">
                                <div className="col-12 pt-3 py-0">
                                    <div className='px-2 field-card'>
                                        <table className='table table-striped'>
                                            <tbody className=''>
                                                {fieldTypeList && fieldTypeList.map(
                                                (field, index) => {
                                                    return(
                                                    <LeadFieldList key={index} field={field} id={data.id} refresh={getFieldTypeList}/>
                                                    )
                                                }
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 row py-3'>
                            <div className='col-7 col-md-9'></div>
                            <div onClick={handleClose} className='btn btn-primary btn-pill col-4 col-md-2'>Done</div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div >
    )
}

export default FollowUpCard