import { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Popup, Dropdown, Search, Icon } from 'semantic-ui-react';
import useAuth from '../../../hooks/useAuth';
import { MEDIA_URL } from '../../../api/axios'

import useReportServices from '../../../services/reportServices';
import useCompanyServices from '../../../services/companyServices';
import useAgentServices from '../../../services/agentServices';
import { DropdownButton } from 'react-bootstrap';
import { DatePicker } from '@mui/x-date-pickers';

const StaffReport = () => {
    const [leadList, setLeadList] = useState()
    const [staffList, setStaffList] = useState()
    const [name, setName] = useState()
    const [image, setImage] = useState()
    const [mobile, setMobile] = useState()
    const [post, setPost] = useState()
    const [months, setMonths] = useState()
    const [closed, setClosed] = useState()
    const [open, setOpen] = useState()
    const [unavailable, setUnavailable] = useState()
    const [paused, setPaused] = useState()
    const [rejected, setRejected] = useState()
    const [total, setTotal] = useState()

    const [filter, setFilter] = useState("Filters")

    const { auth } = useAuth()

    const { getReportStaffLeadList, getReportStaffChart } = useReportServices()
    const { getCompanyStaffList } = useCompanyServices()
    const { getAgentStaffList } = useAgentServices()


    useEffect(() => {
        getStaffList()
    }, []);

    const getStaffList = async () => {
        var response = null
        if (auth.role === 'company') {
            response = await getCompanyStaffList()
            // console.log(response?.data[0])
            setStaffList(response.data)
            var data = response.data[0]
            setName(data.first_name)
            setImage([MEDIA_URL] + data.image)
            setMobile(data.mobile)
            setPost(data.post)
            getLeadList(auth.role, data.fkUser)
            getLeadChart(auth.role, data.fkUser)
        }
        else {
            response = await getAgentStaffList()
            // console.log(response?.data[0].fkStaff)
            setStaffList(response.data)
            var datas = response.data[0].fkStaff
            setName(datas.first_name)
            setImage([MEDIA_URL] + datas.image)
            setMobile(datas.mobile)
            setPost(datas.post)
            getLeadList(auth.role, datas.fkUser)
            getLeadChart(auth.role, datas.fkUser)
        }

    }
    // console.log(leadChart)
    const getLeadList = async (user, userId) => {
        const response = await getReportStaffLeadList(userId, user)
        // console.log(response?.data.leads)
        setLeadList(response.data.leads)
    }
    const getLeadChart = async (user, userId) => {
        const response = await getReportStaffChart(userId, user)
        // console.log(response?.data)
        // setLeadChart(response.data)
        var data = response.data
        setMonths(data['months'])
        setClosed(data['closed'])
        setOpen(data['open'])
        setRejected(data['rejected'])
        setUnavailable(data['unavailable'])
        setPaused(data['paused'])
        setTotal(data['total'])
    }

    const handleSearch = () => {

    }

    //dropdown

    // const dropDownItems = [
    //     { key: 1, text: 'All', value: 1 },
    //     { key: 2, text: 'Closed ', value: 2 },
    //     { key: 3, text: 'Rejected', value: 3 },
    //     { key: 4, text: 'Unavailable', value: 4 },
    //     { key: 5, text: 'Paused', value: 5 },
    //     { key: 6, text: 'Open', value: 6 },
    // ]


    //chart
    const lineOptions = {
        responsive: true,
        maintainAspectRatio: false,
        height: 1000,
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    boxWidth: 15,
                    // boxHeight:15,
                    padding: 15,
                    useBorderRadius: true,
                    borderRadius: 2,
                    font: {
                        size: 15,
                    },
                },
            },
            title: {
                display: false,
                text: 'Monthly Leads',
                font: {
                    size: 20,
                    color: '#000000',
                },
            },
            tooltip: {
                backgroundColor: '#3E66CD',
                yAlign: 'bottom',
                boxWidth: 20,
                padding: 10,
                bodySpacing: 10
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: true,
                    drawTicks: false, //hide datatype lines
                },
                beginAtZero: true,
                ticks: {
                    display: false //hide the datatype values
                }
            },
        },
    };
    const lineLabels = months;
    const lineData = {
        labels: lineLabels,
        datasets: [
            {
                label: 'Closed',
                data: closed,
                borderColor: '#35D40D',
                borderWidth: 1.5,
                backgroundColor: '#35D40D',
                tension: 0.5
            },
            {
                label: 'Rejected',
                data: rejected,
                borderColor: '#D31C1C',
                borderWidth: 1.5,
                backgroundColor: '#D31C1C',
                tension: 0.5
            },
            {
                label: 'Unavailable',
                data: unavailable,
                borderColor: '#DEC910',
                borderWidth: 1.5,
                backgroundColor: '#DEC910',
                tension: 0.5
            },
            {
                label: 'Paused',
                data: paused,
                borderColor: '#9745FF',
                borderWidth: 1.5,
                backgroundColor: '#9745FF',
                tension: 0.5
            },
            {
                label: 'Opened',
                data: open,
                borderColor: '#25B4D3',
                borderWidth: 1.5,
                backgroundColor: '#25B4D3',
                tension: 0.5
            },
            {
                label: 'New',
                data: total,
                borderColor: '#000000',
                borderWidth: 1.5,
                backgroundColor: '#000000',
                tension: 0.5
            },
        ],
    };
    return (
        <div className=''>
            <div className="row">
                {window.innerWidth <= 768 &&
                    <div className="col-12">
                        <div className="row mb-3 pe-0">
                            <div className={" col-5"}>
                                <div className=" report-staff-card selected py-4 px-2">
                                    <div className=" my-2 px-0 d-flex justify-content-center">
                                        <img src={image} className="report-staff-img" alt="" />
                                    </div>
                                    <div className=" my-3 px-4">
                                        <div className={"report-staff-card-title mb-2"}>{name}</div>
                                        <div className={"report-staff-card-position mb-1"}>{post}</div>
                                        <div className={"report-staff-card-phone"}>{mobile}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-7">
                                <div className={"report-staff-card default p-3 py-4 "}>
                                    <div className="report-staff-card-head-title h4 mb-3">
                                        Lead Team Members
                                    </div>
                                    <div className="scrollable-agent-list">
                                        {staffList && staffList.map(
                                            (data, index) => {
                                                return (
                                                    auth.role === 'company' ?
                                                        <div key={index} className="row my-2 mouse-pointer" onClick={() => { setImage(MEDIA_URL + data.image); setName(data.first_name); setMobile(data.mobile); setPost(data.post); getLeadList(auth.role, data.fkUser); getLeadChart(auth.role, data.fkUser) }}>
                                                            <div className="col-4 report-staff-card-img">
                                                                <img src={data.image && MEDIA_URL + data.image} alt="" />
                                                            </div>
                                                            <div className="col-8 my-auto">
                                                                <div className={"report-staff-card-title mb-1"}>{data.first_name}</div>
                                                                <div className={"report-staff-card-position mb-1"}>{data.post}</div>

                                                            </div>
                                                        </div>
                                                        :
                                                        <div key={index} className="row my-2 mouse-pointer" onClick={() => { setImage(MEDIA_URL + data.fkStaff.image); setName(data.fkStaff.first_name); setMobile(data.fkStaff.mobile); setPost(data.fkStaff.post); getLeadList(auth.role, data.fkStaff.fkUser); getLeadChart(auth.role, data.fkStaff.fkUser) }}>
                                                            <div className="col-md-4 report-staff-card-img">
                                                                <img src={data.fkStaff.image && MEDIA_URL + data.fkStaff.image} alt="" />
                                                            </div>
                                                            <div className="col-md-8 my-auto">
                                                                <div className={"report-staff-card-title mb-1"}>{data.fkStaff.first_name}</div>
                                                                <div className={"report-staff-card-position mb-1"}>{data.fkStaff.post}</div>

                                                            </div>
                                                        </div>
                                                )
                                            }
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                <div className="col-12 col-md-9 ps-0">
                    <div className="row mb-3">
                        <div className="report-pane-staff p-4">
                            <div className="row mb-3">
                                <div className="col-3 report-staff-chart-title">
                                    Monthly Leads
                                </div>
                            </div>
                            <div className="row p-3">
                                <div className="report-pane-chart p-3" style={{ height: 350 }}>
                                    <Line data={lineData} options={lineOptions} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="report-pane-staff p-4">
                            {/* <div className="row">
                                <div className="report-staff-chart-title col-md-6">Monthly Lead List</div>
                                <div className="row pe-1">
                                    <div id="leadsearch" className="row z-index-n1 pe-1">
                                        {auth?.role === "company" &&
                                            <div id="searchLead" className="col-3 me-0 pe-1 my-3">
                                                <div className="me-0">
                                                    <Search
                                                        input={{ icon: 'search', iconPosition: 'left', fluid: true }}
                                                        className="lead-searchBox"
                                                        placeholder="Search Here..."
                                                        size='large'
                                                        fluid={true}
                                                        onSearchChange={handleSearch}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        <div className="col-md-2 my-3">
                                            <DropdownButton id="dropdown-basic-button" title={filter}>
                                                <Dropdown.Item onClick={() => setFilter("Status")}>Status</Dropdown.Item>
                                                <Dropdown.Item onClick={() => setFilter("Followup")}>Followup</Dropdown.Item>
                                                <Dropdown.Item onClick={() => setFilter("Specific day")}>Specific day</Dropdown.Item>
                                                <Dropdown.Item onClick={() => setFilter("Date Range")}>Date Range</Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                        {filter === "Specific day" && <div className="col-md-2 my-3">
                                            <DatePicker
                                                className='lead-datepicker col-12'
                                            />
                                        </div>}
                                        {filter === "Date Range" &&
                                            <><div className="col-md-2 my-3">
                                                <DatePicker
                                                    className='lead-datepicker col-12'
                                                />
                                            </div><div className="col-md-2 my-3">
                                                    <DatePicker
                                                        className='lead-datepicker col-12'
                                                    />
                                                </div></>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <Search className='report-staff-chart-sub'
                                        input={{ icon: 'search', iconPosition: 'left', fluid: true }}
                                        placeholder="Search Here..."
                                        size='mini'
                                        onSearchChange={handleSearch}

                                    />
                                </div>
                                <div className="col-md-3">
                                    <Dropdown
                                        className='report-staff-drop report-staff-chart-sub'
                                        selection
                                        fluid={true}
                                        placeholder="Select status"
                                        options={dropDownItems} />
                                </div>
                            </div> */}
                            <div className="row p-3">
                                <div className="report-pane-table p-3 ">
                                    <div className="table-responsive">
                                        <table className="table table-striped ">
                                            <thead>
                                                <tr>
                                                    <td>Sl no.</td>
                                                    <td>Name</td>
                                                    <td>Email</td>
                                                    <td>Phone number</td>
                                                    <td>Interested</td>
                                                    <td>Followup Stage</td>
                                                    <td>Status</td>
                                                    <td align='center'>Description</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {leadList && leadList.map(
                                                    (data, index) => {
                                                        return (
                                                            // data.lead.status != 'New'?
                                                            <tr className='status-container ' key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{data.lead.name}</td>
                                                                <td>{data.lead.email}</td>
                                                                <td>{data.lead.mobile}</td>
                                                                <td>{data.lead.interest}</td>
                                                                <td>{data.lead.follow_up}</td>
                                                                <td><div className={' ' + data.lead.status}>{data.lead.status}</div></td>
                                                                <td align='center' className='text-primary'>
                                                                    <Popup
                                                                        trigger={<Icon size="large" name='file alternate' />}
                                                                        position='bottom right'
                                                                        size='mini'
                                                                    >
                                                                        <Popup.Header>Description</Popup.Header>
                                                                        <Popup.Content>{data.lead.description}
                                                                        </Popup.Content>
                                                                    </Popup>
                                                                </td>
                                                            </tr>
                                                            // :<></>
                                                        )
                                                    }
                                                )}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {window.innerWidth > 768 && <div className="col-3">
                    <div className="row mb-3 ms-3 pe-0">
                        <div className={"report-staff-card selected row p-4 px-4"}>
                            <div className="col-4 my-2 px-0">
                                <img src={image} className="report-staff-img" alt="" />
                            </div>
                            <div className="col-8 my-3 px-4">
                                <div className={"report-staff-card-title mb-2"}>{name}</div>
                                <div className={"report-staff-card-position mb-1"}>{post}</div>
                                <div className={"report-staff-card-phone"}>{mobile}</div>
                            </div>
                        </div>
                        <div className={"report-staff-card default col-12 row p-3 py-4 "}>

                            <div className="report-staff-card-head-title row h4 mb-3">
                                Lead Team Members
                            </div>
                            <div className=" my-2">
                                {staffList && staffList.map(
                                    (data, index) => {
                                        return (
                                            auth.role === 'company' ?
                                                <div key={index} className="row my-2 mouse-pointer" onClick={() => { setImage(MEDIA_URL + data.image); setName(data.first_name); setMobile(data.mobile); setPost(data.post); getLeadList(auth.role, data.fkUser); getLeadChart(auth.role, data.fkUser) }}>
                                                    <div className="col-md-4 report-staff-card-img">
                                                        <img src={data.image && MEDIA_URL + data.image} alt="" />
                                                    </div>
                                                    <div className="col-md-8 my-auto">
                                                        <div className={"report-staff-card-title mb-1"}>{data.first_name}</div>
                                                        <div className={"report-staff-card-position mb-1"}>{data.post}</div>

                                                    </div>
                                                </div>
                                                :
                                                <div key={index} className="row my-2 mouse-pointer" onClick={() => { setImage(MEDIA_URL + data.fkStaff.image); setName(data.fkStaff.first_name); setMobile(data.fkStaff.mobile); setPost(data.fkStaff.post); getLeadList(auth.role, data.fkStaff.fkUser); getLeadChart(auth.role, data.fkStaff.fkUser) }}>
                                                    <div className="col-md-4 report-staff-card-img">
                                                        <img src={data.fkStaff.image && MEDIA_URL + data.fkStaff.image} alt="" />
                                                    </div>
                                                    <div className="col-md-8 my-auto">
                                                        <div className={"report-staff-card-title mb-1"}>{data.fkStaff.first_name}</div>
                                                        <div className={"report-staff-card-position mb-1"}>{data.fkStaff.post}</div>

                                                    </div>
                                                </div>
                                        )
                                    }
                                )}
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default StaffReport