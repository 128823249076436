import { Form, Modal, Nav, Tab } from 'react-bootstrap';
import { useState } from 'react';
import useCustomerServices from '../../../services/customerServices';
import Swal from 'sweetalert2';
import CustomerProfile from './CustomerProfile';
import CustomerDocumentList from './CustomerDocumentList';
import CustomerInterestList from './CustomerInterestList';
import ProductAddForm from './ProductAddForm';
import { MdAddBox } from 'react-icons/md';
import LeadFieldList from '../../leads/components/LeadFieldList';
import useLeadServices from '../../../services/leadServices';
// import '../Customer.css'

const CustomerTable = ({ index, data, statusList, refresh}) => {
  const [showProfile, setShowProfile] = useState(false)
  const [showAdd, setShowAdd] = useState(false)
  const [docTypeList, setDocTypeList] = useState("")
  const [interestList, setInterestList] = useState("")
  const [status, setStatus] = useState(data?.status||"Select")
  const [payment, setPayment] = useState(data?.payment||"Pending")
  const [key, setKey] = useState("tab1");
  const [fieldTypeList, setFieldTypeList] = useState("")
  var fieldData = [];

  const {putChangeCustomerStatus, getActiveDocTypes, getCustomerIntrests, putChangePaymentStatus} = useCustomerServices()
  const { getActiveFieldTypes } = useLeadServices()

  const handleClose = () => setShowProfile(false)
  const handleAddClose = () => setShowAdd(false)

  const handleClick = () => {
    getDocTypeList()
    getInterestList()
    getFieldTypeList()
    setShowProfile(true)
  }

  const getFieldTypeList = async () => {
    const response = await getActiveFieldTypes()
    if (response !== null) {
        // console.log(response?.data)
        setFieldTypeList(response.data)
    }
  }

  const getDocTypeList = async () => {
    const response = await getActiveDocTypes()
    if (response !== null) {
        // console.log(response?.data)
        setDocTypeList(response.data)
    }
  }

  const getInterestList = async () => {
    const response = await getCustomerIntrests(data.id)
    if (response !== null) {
        setInterestList(response.data)
    }
  }

  const handleChange = async (e) => {
    e.preventDefault()
    setStatus(e.target.value)
    try {
      const datas = JSON.stringify({
        status:e.target.value
      });
      const response = await putChangeCustomerStatus(data.id, datas)
      // console.log(response?.data)
      if (response.success) {
        Swal.fire("", "Customer Status Changed Successfully", "success")
        refresh()
      }
    }
    catch (err) {
      //console.log(err)
    }
  }
  const handlePayment = async (e) => {
    e.preventDefault()
    setPayment(e.target.value)
    try {
      const datas = JSON.stringify({
        payment:e.target.value
      });
      const response = await putChangePaymentStatus(data.id, datas)
      // console.log(response?.data)
      if (response.success) {
        Swal.fire("", "Customer Payment Status Changed Successfully", "success")
        refresh()
      }
    }
    catch (err) {
      //console.log(err)
    }
  }
  const getDate = ()=>{
    return new Date(data?.created_at).toString().slice(4,15)
  }

  return (
    <>
    <tr className="my-2">
      <td className="data-color table-data ps-4">{index+1}</td>
      <td className="mouse-pointer table-data text-primary" onClick={()=>handleClick()}>{data.name}</td>
      <td className="data-color table-data ">{data.mobile}</td>
      <td className="data-color table-data ">{data.email}</td>
      <td className="data-color table-data ">{getDate()}</td>
      <td className="data-color table-dropdown">
        <Form.Select
          className={"payment-dropdown mouse-pointer "+payment}
          size="md"
          value={payment}
          onChange={(e)=>handlePayment(e)}
        >
          <option className="menu" value="Pending">Pending</option>
          <option className="menu" value="Partially Paid">Partially Paid</option>
          <option className="menu" value="Paid">Paid</option>          
        </Form.Select>
      </td>
      <td className="data-color table-dropdown">
        <Form.Select
          className="status-dropdown mouse-pointer"
          size="md"
          value={status}
          onChange={(e)=>handleChange(e)}
        >
          <option className="menu" value="Select">Select</option>
          {statusList && statusList.map(
            (data, index) => {
              return(
                <option key={index} className="menu" value={data.name}>{data.name}</option>
              )
            }
          )}
          
        </Form.Select>
      </td>
    </tr>
    <Modal
      show={showProfile}
      onHide={handleClose}
      centered={true}
      size="xl"
      contentClassName="lead-modal px-md-4 py-2 p-4"
      >
      <Modal.Body className=''>
        <div className='card document-card row'>
            <CustomerProfile data={data} refresh={refresh} handleProfileClose={handleClose} type={"Customer"} />
          <div className='col-12'>
            <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
              <div className="row ">
                <div className="col-12">
                  <Nav variant="pills" className="flex-md-row flex-column">
                  ` <Nav.Item className='pt-2 px-md-3'>
                      <Nav.Link className='py-3  px-md-4 nav-panes' eventKey="tab1">Details</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className='pt-2 pe-md-3'>
                      <Nav.Link className='py-3 nav-panes' eventKey="tab2">Documents</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className='pt-2'>
                      <Nav.Link className='py-3 nav-panes' eventKey="tab3">Products</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="col-12 document-card px-3 py-0">
                  <Tab.Content>
                    <Tab.Pane eventKey="tab1" className=" pt-4">
                      <div className='field-data-card px-3'>
                        <table className='table table-striped'>
                          <tbody className=''>
                              {fieldTypeList && fieldTypeList.map(
                                (field, index) => {
                                  return(
                                  <LeadFieldList key={index} field={field} id={data.id} refresh={getFieldTypeList} edit={fieldData} phase={"customer"} />
                                  )
                                }
                              )}
                          </tbody>
                        </table>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="tab2" className=" pt-4">
                      <div className=' table-responsive table-card'>
                        <table className='table table-striped'>
                          <thead></thead>
                          <tbody className='customer-table'>
                            {docTypeList && docTypeList.map(
                              (type, index) => {
                                return(
                                  <CustomerDocumentList key={index} type={type} id={data.id} refresh={getDocTypeList}/>
                                )
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="tab3" className="">
                    <div className='row'>
                      {window.innerWidth>768&&<div className=' col-10'></div>}
                      <div className='col-md-2 col-12 pb-3 px-3 pt-md-0 pt-3'>
                        <div className='ms-md-1 btn add-interest-btn btn-pill fs-5 pe-3 col-12' onClick={()=>setShowAdd(true)}><MdAddBox size="25px" /> <b>Add</b></div>
                      </div>
                    </div>
                    <div className='row table-responsive px-3 table-card'>
                        <table className='table table-striped'>
                          <tbody className='customer-table'>
                            {interestList && interestList.map(
                              (interest, index) => {
                                return(
                                  <CustomerInterestList key={index} interest={interest} id={data.id} refresh={getInterestList}/>
                                )
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </div>
          <div className='row py-3'>
            <div className='col-7 col-md-9'></div>
            <div onClick={handleClose} className='btn btn-primary btn-pill col-4 col-md-2'>Done</div>
          </div>
          <Modal
            show={showAdd}
            onHide={handleClose}
            centered
            size="lg"
            contentClassName="add-interest"
          >
            <Modal.Body className="p-4">
              <ProductAddForm data={null} refresh={getInterestList} handleClose={handleAddClose} id={data.id} />
            </Modal.Body>
          </Modal>
        </div>
      </Modal.Body>
      </Modal>
  </>
  )
}

export default CustomerTable