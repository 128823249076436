import useAxiosPrivate from "../hooks/useAxiosPrivate";

const useCompanyServices = () => {
    const axiosPrivate = useAxiosPrivate()

    //get dashboard data
    const getCompanyDashboard = async () => {
        const response = await axiosPrivate.get("/company/dashboard/")
        return response.data
    }

    //get staff list
    const getCompanyStaffList = async () => {
        const response = await axiosPrivate.get("/company/staff_list/")
        return response.data
    }

    //get staff details
    const getCompanyStaffDetails = async (id) => {
        const response = await axiosPrivate.get("/company/staff_details/" + id + "/")
        return response.data
    }

    //edit staff details
    const putCompanyStaffDetails = async (id, data) => {
        const response = await axiosPrivate.put("/company/staff_details/" + id + "/", data,{
            headers:{"Content-Type":"multipart/form-data"}
        })
        return response.data
    }

    //delete staff details
    const deleteCompanyStaffDetails = async (id) => {
        const response = await axiosPrivate.delete("/company/staff_details/" + id + "/")
        return response.data
    }

    //change staff status
    const putStaffStatusChange = async (id, data = null) => {
        const response = await axiosPrivate.put("/company/staff_status_change/" + id + "/", data = null)
        return response.data
    }

    //get agent list
    const getAgentListToday = async () => {
        const response = await axiosPrivate.get("/company/agent_list/")
        return response.data
    }

    //get agent details
    const getAgentDetailsToday = async (id) => {
        const response = await axiosPrivate.get("/company/agent_details/" + id + "/")
        return response.data
    }

    //edit agent details
    const putAgentDetailsToday = async (id, data) => {
        const response = await axiosPrivate.put("/company/agent_details/" + id + "/", data,{
            headers:{'Content-Type':'multipart/form-data'}
        })
        return response.data
    }

    //delete agent details
    const deleteAgentDetailsToday = async (id) => {
        const response = await axiosPrivate.delete("/company/agent_details/" + id + "/")
        return response.data
    }

    //change agent status
    const putAgentStatusChange = async (id, data = null) => {
        const response = await axiosPrivate.put("/company/agent_status_change/" + id + "/", data = null)
        return response.data
    }

    //get staff list to assign
    const getStaffAssignAgent = async (id, choice) => {
        const response = await axiosPrivate.get("/company/staff_assign_agent/"+id+"/", {
            params: { "choice": choice }
        })
        return response.data
    }

    //assign staff to agents
    const postStaffAssignAgent = async (id, data) => {
        const response = await axiosPrivate.post("/company/staff_assign_agent/"+id+"/", data)
        return response.data
    }

    //get lead list
    const getLeadListToday = async () => {
        const response = await axiosPrivate.get("/company/lead_list/")
        return response.data
    }

    //add new lead
    const postLeadListToday = async (data) => {
        const response = await axiosPrivate.post("/company/lead_list/", data)
        return response.data
    }

    //get lead details
    const getLeadDetailsToday = async (id) => {
        const response = await axiosPrivate.get("/company/lead_details/" + id + "/")
        return response.data
    }

    //edit lead details
    const putLeadDetailsToday = async (id, data) => {
        const response = await axiosPrivate.put("/company/lead_details/" + id + "/", data)
        return response.data
    }

    //delete lead details
    const deleteLeadDetailsToday = async (id) => {
        const response = await axiosPrivate.delete("/company/lead_details/" + id + "/")
        return response.data
    }

    //get leads list to assign
    const getAgentAssignLead = async (id, choice) => {
        const response = await axiosPrivate.get("/company/agent_assign_lead/"+id+"/", {
            params: { "choice": choice }
        })
        return response.data
    }

    //assign leads to agents
    const postAgentAssignLead = async (id, data) => {
        const response = await axiosPrivate.post("/company/agent_assign_lead/"+id+"/", data)
        return response.data
    }

    //get follow_up list
    const getFollowUpList = async () => {
        const response = await axiosPrivate.get("/company/follow_up_list/")
        return response.data
    }

    //get company profile details
    const getCompanyProfile = async () => {
        const response = await axiosPrivate.get("/company/profile/")
        return response.data
    }

    //edit company profile details
    const putCompanyProfile = async (data) => {
        const response = await axiosPrivate.put("/company/profile/", data,{
            headers: { 'Content-Type': 'multipart/form-data'},
        })
        return response.data
    }

    //returns
    return {
        getCompanyDashboard,
        getCompanyStaffList,
        getCompanyStaffDetails,
        putCompanyStaffDetails,
        deleteCompanyStaffDetails,
        putStaffStatusChange,
        getAgentListToday,
        getAgentDetailsToday,
        putAgentDetailsToday,
        deleteAgentDetailsToday,
        putAgentStatusChange,
        getStaffAssignAgent,
        postStaffAssignAgent,
        getLeadListToday,
        postLeadListToday,
        getLeadDetailsToday,
        putLeadDetailsToday,
        deleteLeadDetailsToday,
        getAgentAssignLead,
        postAgentAssignLead,
        getFollowUpList,
        getCompanyProfile,
        putCompanyProfile
    }
}
export default useCompanyServices