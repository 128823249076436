import './AddUserForm.css'
import { Form } from 'react-bootstrap'
// import { Dropdown } from 'semantic-ui-react'

const AddUserForm = (props) => {
    const { imagePreview, handleImage, handleSubmit, handleClose } = props
    const {
        agentName,setAgentName,
        agentPost, setAgentPost,
        agentPhone, setAgentPhone,
        agentEmail, setAgentEmail
    }=props

    // const options = [
    //     { key: 1, text: 'Update Lead', value: 1 },
    //     { key: 2, text: 'Assign Lead', value: 2 },
    //     { key: 3, text: 'Call Lead', value: 3 },
    //     { key: 4, text: "None", value: 4 },
    // ]
    return (
        <div className="">
            <div className="row">
                <div className="h3 fw-bolder">{props.title}</div>
            </div>
            <Form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-4">
                        <div className=' p-3'>
                            <label htmlFor="uploadStaffImg" className='adduser-img col-12'>

                                <img src={imagePreview} 
                                // className={selected != "" ? "w-100" : ""} 
                                alt="" />

                            </label>
                            <input id='uploadStaffImg' onChange={(e) => handleImage(e)} hidden className='form-control' type="file" accept='image/*' />
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="row my-4">
                            <div className="col-md-7 px-1">
                                <Form.Group className='' controlId='staffName'>
                                    <Form.Label className='fw-bolder'>Name</Form.Label>
                                    <Form.Control value={agentName} onChange={(e)=>setAgentName(e.target.value)} className='py-2' placeholder="Eg: Beserac" />
                                </Form.Group>
                            </div>
                            <div className="col-md-5 px-1">
                                <Form.Group className='' controlId='staffPost'>
                                    <Form.Label className='fw-bolder'>Post</Form.Label>
                                    <Form.Control  value={agentPost}  onChange={(e)=>setAgentPost(e.target.value)} className='py-2' placeholder="BDE" />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className="col-5 px-1">
                                <Form.Group className='' controlId='staffPhone'>
                                    <Form.Label className='fw-bolder'>Phone number</Form.Label>
                                    <Form.Control type='tel' value={agentPhone}  onChange={(e)=>setAgentPhone(e.target.value)} className='py-2' placeholder="+91 9876543210" />
                                </Form.Group>
                            </div>
                            <div className="col-7 px-1">
                                <Form.Group className='' controlId='staffPost'>
                                    <Form.Label className='fw-bolder'>Email</Form.Label>
                                    <Form.Control type='email'  value={agentEmail}  onChange={(e)=>setAgentEmail(e.target.value)} className='py-2' placeholder="user@example.com" />
                                </Form.Group>
                            </div>
                        </div>
                        {/* {props.admin &&  */}
                        <div className="row my-4">
                            <div className="col-md-6 px-1">
                                {/* <Dropdown
                                placeholder='Select access'
                                 className='col-10'
                                  clearable options={options} 
                                  selection /> */}

                            </div>
                            <div className="row col-md-6">
                                <div className="col-6">
                                    <button onClick={handleClose} type='button' className='btn btn-outline-primary adduser-btn col-12'>Cancel</button>
                                </div>
                                <div className="col-6">
                                    <button type='submit' className='btn btn-primary adduser-btn col-12'>Save</button>
                                </div>
                            </div>
                        </div>
                        {/* } */}

                    </div>
                </div>
                {/* {!props.admin &&
                    <div className="row adduser-btn-container">
                        <div className="col-md-6"></div>
                        <div className="row col-md-6">
                            <div className="col-md-6">
                                <button onClick={handleClose} type='button' className='btn btn-outline-primary adduser-btn col-12'>Cancel</button>
                            </div>
                            <div className="col-md-6">
                                <button type='submit' className='btn btn-primary adduser-btn col-12'>Save</button>
                            </div>
                        </div>
                    </div>} */}
            </Form>
        </div>
    )
}

export default AddUserForm