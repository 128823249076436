
const DashboardCard = ({img,title,count,classes}) => {
    return (
        <div className="col-lg-3 col-md-4 my-3">
            <div className={"dashboard-card col-12 row p-3 py-4 "+classes}>
                <div className={"col-3 dashboard-card-img my-2 "+classes}>
                    <img src={img} className=" w-75" alt="" />
                </div>
                <div className="col-9 my-2">
                    <div className={"dashboard-card-title "+classes}>{title}</div>
                    <div className={"dashboard-card-count "+classes}>{count||0}</div>
                </div>
            </div>
        </div>
    )
}

export default DashboardCard