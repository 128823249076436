import { useEffect } from "react"
import { useState } from "react"
import { Form, Modal } from "react-bootstrap"
import { Popup, Icon } from "semantic-ui-react"
import Swal from "sweetalert2"
import useLeadServices from "../../../services/leadServices"
import useCustomerServices from "../../../services/customerServices"
import CustomerAddForm from "./CustomerAddForm"
import CustomerProfile from "../../customer/components/CustomerProfile"
import LeadFieldList from "./LeadFieldList"

const


    LeadCard = ({ index, data, auth, updateLeads }) => {
        const [descModal, setDescModal] = useState(false)
        const [form, setForm] = useState(false)
        const [showProfile, setShowProfile] = useState(false)
        const [isShown, setIsShown] = useState(false);

        const { putLeadStatusChange, getActiveFieldTypes } = useLeadServices()
        const { getCustomerCheck } = useCustomerServices()
        //console.log(1)    

        const [id, setId] = useState("")
        const [name, setName] = useState("")
        const [mobile, setMobile] = useState("")
        const [mail, setMail] = useState("")
        const [course, setCourse] = useState("")
        const [location, setLocation] = useState("")
        const [description, setDescription] = useState("")
        const [status, setStatus] = useState("")
        const [fieldTypeList, setFieldTypeList] = useState("")
        var fieldData = [];


        const loadData = () => {
            setId(data.id)
            setName(data.name)
            setMobile(data.mobile)
            setMail(data.email)
            setCourse(data.interest)
            setLocation(data.location)
            setStatus(data.status)
            setDescription(data.description)
        }
        useEffect(() => {
            loadData()
            if (data.status === "Closed" && auth.role === "staff") {
                CheckCustomerExists(data.id)
            }
            getFieldTypeList()
        }, [data])

        const getFieldTypeList = async () => {
            const response = await getActiveFieldTypes()
            if (response !== null) {
                // console.log(response?.data)
                setFieldTypeList(response.data)
            }
        }

        const handleCloseDesc = () => setDescModal(false);

        const handleSubmit = async (key, e) => {
            e && e.preventDefault()
            var datas = null
            // console.log(e.target.value)
            if (key === "status") {
                setStatus(e.target.value)
                datas = JSON.stringify({
                    'status': e.target.value
                });
            }
            else if (key === "description") {
                datas = JSON.stringify({
                    'description': description
                });
            }
            const response = await putLeadStatusChange(id, data = datas)
            if (response?.success) {
                handleCloseDesc()
                if (key === "status" && e.target.value === "Closed") {
                    CheckCustomerExists(id)
                }
                updateLeads()
            }
            else {
                Swal.fire("", response?.message, "error")
            }
        }

        const getDate = (data) => {
            return new Date(data).toString().slice(4, 15)
        }

        const handleDesc = () => setIsShown(!isShown)

        const CheckCustomerExists = async (data) => {
            const response = await getCustomerCheck(data)
            // console.log(response?.data?.exist)
            if (response?.data?.exist === false) {
                setForm(true)
                updateLeads()
            }
        }

        const handleFormClose = () => {
            setForm(false)
        }
        const handleClose = () => setShowProfile(false)

        return (
            <div className="col-lg-12 me-5 ">
                <Modal
                    size="lg"
                    centered
                    show={descModal}
                    onHide={handleCloseDesc}
                    contentClassName="lead-desc-modal px-5"
                >
                    <Modal.Body>
                        <Form onSubmit={(e) => handleSubmit("description", e)}>
                            <div className="row mb-3 my-3">
                                <div className="h3 fw-bold">Add Description</div>
                            </div>
                            <div className="row mb-3">
                                <Form.Control className="lead-desc-area p-4" as="textarea" placeholder="enter lead status description" value={description} onChange={(e) => setDescription(e.target.value)} />
                            </div>
                            <div className="row mb-3">
                                <div className="float-end">
                                    <button className="btn btn-primary btn-pill float-end col-4">Save</button>
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
                {window.innerWidth > 768 ?
                    <div className="lead-card col-12 row p-3 pe-0 me-3 ">
                        <div className="col-1 pe-0 py-1 my-2  lead-card-title">{index}</div>
                        <div className="col-1 my-2 lead-card-title p-1 px-0 overflow-auto mouse-pointer text-primary" onClick={(e) => setShowProfile(true)}>{name}</div>
                        <div className="col-1 my-2 lead-card-title p-1 pe-0 ps-2">{mobile}</div>
                        <div className="col-3 my-2 lead-card-title p-1 ps-5 pe-0 overflow-auto">{mail}</div>
                        <div className="col-1 my-2 lead-card-title p-1 ps-0 overflow-auto">{getDate(data?.created_at)}</div>
                        <div className="col-1 my-2 lead-card-title p-1 ps-0 overflow-auto">{getDate(data?.updated_at)}</div>
                        <div className="col-1 my-2 lead-card-title p-1 pe-0 ps-1 overflow-hidden">{course}</div>
                        <div className="col-1 my-2 lead-card-title p-1 pe-0 ps-2 overflow-hidden">{location}</div>

                        {auth.role === "company" || auth.role === "agent" ?
                            <>
                                <div className="col-1 my-2 assign-lead pe-0 ps-1 p-1">
                                    <div className={" " + data?.assigned_to}>{data?.assigned_to}</div>
                                </div>
                                <div className="col-1 my-2 p-1  pe-0 lead-status-container pe-4">
                                    <div className={"lead-status text-center col-12 " + data?.status}>{data.status}</div>
                                </div>
                            </>
                            :
                            <>
                                <div className="col-1 lead-card-title my-2 px-0">
                                    <div className="">
                                        <Form.Select
                                            value={status}
                                            onChange={(e) => (handleSubmit("status", e))}
                                            className="status-dropdown"
                                            size="sm"
                                            disabled={status === 'Closed' ? true : false}
                                        >
                                            <option className="menu" value="New">New</option>
                                            <option className="menu" value="Open">Open</option>
                                            <option className="menu" value="Closed">Closed</option>
                                            <option className="menu" value="Paused">Paused</option>
                                            <option className="menu" value="Rejected">Rejected</option>
                                            <option className="menu" value="Unavailable">Unavailable</option>
                                        </Form.Select>
                                    </div>
                                </div>
                                <div className="col-1 my-2 text-primary text-center pe-4" onMouseEnter={() => handleDesc()} onMouseLeave={() => handleDesc()} >
                                    <Popup
                                        trigger={<Icon onClick={() => setDescModal(true)} size="large" name='file alternate' disabled={status === 'Closed' ? true : false} />}
                                        position='bottom right'
                                        size='mini'
                                    >
                                        <Popup.Header>Description</Popup.Header>
                                        <Popup.Content>{description}
                                        </Popup.Content>
                                    </Popup>


                                </div>
                                {auth.role === "staff" &&
                                    <div className=" ">
                                        <Modal
                                            show={form}
                                            onHide={handleFormClose}
                                            centered={true}
                                            size="lg"
                                            contentClassName="lead-modal"
                                        >
                                            <Modal.Body className='p-5'>
                                                <CustomerAddForm
                                                    handleClose={handleFormClose}
                                                    datas={data}
                                                />
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                }
                            </>
                        }
                    </div>
                    :
                    <div className="followup-card responsive col-12 p-3 pe-0 my-2">
                        <table className=" my-2 followup-card-title followup-data p-1 pe-5 ps-3 ">
                            <tr>
                                <th>Name</th>
                                <td>
                                    <div className=" overflow-auto mouse-pointer text-primary" onClick={(e) => setShowProfile(true)}>{data?.name}</div></td>
                            </tr>
                            <tr>
                                <th>Phone</th>
                                <td>{mobile}</td>
                            </tr>
                            <tr>
                                <th>E-mail</th>
                                <td>{mail}</td>
                            </tr>
                            <tr>
                                <th className="">Created On</th>
                                <td>{getDate(data?.created_at)}</td>
                            </tr>
                            <tr>
                                <th>Updated On</th>
                                <td>{getDate(data?.updated_at)}</td>
                            </tr>
                            <tr>
                                <th>Interested</th>
                                <td>{course}</td>
                            </tr>
                            <tr>
                                <th>Location</th>
                                <td>{location}</td>
                            </tr>
                            {auth.role === "company" || auth.role === "agent" ?
                                <>
                                    {/* <tr>
                                        <th>Created On</th>
                                        <td>{getDate(data?.created_at)}</td>
                                    </tr> */}
                                    {/* {auth.role === "company" &&  */}
                                    <tr>
                                        <th>Assigned to</th>
                                        <td>
                                            <div className=" assign-lead pe-0 ">
                                                <div className={" " + data?.assigned_to}>{data?.assigned_to}</div>
                                            </div>

                                        </td>
                                    </tr>
                                    {/* } */}
                                    <tr>
                                        <th>Status</th>
                                        <td>
                                            <div className=" lead-status-container ">
                                                <div className={"lead-status text-center col-12 " + data?.status}>{data.status}</div>
                                            </div>
                                        </td>
                                    </tr>
                                    {/* {auth.role === "agent" &&
                                        <tr>
                                            <th>Desc</th>
                                            <td>
                                                <div className=" my-2  ps-2 text-primary text-center pe-4">
                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                        <Form.Control className="col-12" readOnly as="textarea" value={description} onClick={() => setDescModal(true)} rows="2" />
                                                    </Form.Group>
                                                </div>
                                            </td>
                                        </tr>} */}
                                </>
                                :
                                <>
                                    <tr>
                                        <th>Status</th>
                                        <td>
                                            <div className="">
                                                <Form.Select
                                                    value={status}
                                                    onChange={(e) => (handleSubmit("status", e))}
                                                    className="status-dropdown"
                                                    disabled={status === 'Closed' ? true : false}
                                                    size="sm"
                                                >
                                                    <option className="menu" value="New">New</option>
                                                    <option className="menu" value="Open">Open</option>
                                                    <option className="menu" value="Closed">Closed</option>
                                                    <option className="menu" value="Paused">Paused</option>
                                                    <option className="menu" value="Rejected">Rejected</option>
                                                    <option className="menu" value="Unavailable">Unavailable</option>
                                                </Form.Select>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Desc</th>
                                        <td>
                                            <div className=" my-2 text-primary text-center pe-4">
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                    <Form.Control disabled={status === 'Closed' ? true : false} className="col-12" readOnly as="textarea" value={description} onClick={() => setDescModal(true)} rows="2" />
                                                </Form.Group>
                                            </div>
                                        </td>
                                    </tr>
                                </>
                            }
                        </table>
                    </div>
                }
                <Modal
                    show={showProfile}
                    onHide={handleClose}
                    centered={true}
                    size="xl"
                    contentClassName="lead-modal px-md-4 py-2 p-4"
                >
                    <Modal.Body className=''>
                        <div className='card document-card row'>
                            <CustomerProfile data={data} refresh={updateLeads} handleProfileClose={handleClose} type={"Lead"} />
                            <div className='col-12'>
                                <div className="row">
                                    <div className="col-12 pt-3 py-0">
                                        <div className='px-2 field-card'>
                                            <table className='table table-striped'>
                                                <tbody className=''>
                                                    {fieldTypeList && fieldTypeList.map(
                                                        (field, index) => {
                                                            return (
                                                                <LeadFieldList key={index} field={field} id={data.id} refresh={getFieldTypeList} edit={fieldData} />
                                                            )
                                                        }
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 row py-3'>
                                <div className='col-7 col-md-9'></div>
                                <div onClick={handleClose} className='btn btn-primary btn-pill col-4 col-md-2'>Done</div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }

export default LeadCard