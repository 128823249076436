import React from 'react'
import { Form } from 'react-bootstrap'

const LeadAddForm = (props) => {
    const {handleSubmit,handleClose,setLeadName,setLeadEmail,setLeadMobile,setLeadLocation,setLeadInterest,setLeadSource} = props
    return (
        <div className="">
            <div className="row">
                <div className="h3 fw-bolder">Add Lead</div>
            </div>
            <Form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row my-4">
                            <div className="col-md-8 px-1">
                                <Form.Group className='' controlId='leadName'>
                                    <Form.Label className='fw-bolder'>Name</Form.Label>
                                    <Form.Control className='py-2' placeholder="Beserac" onChange={(e)=>setLeadName(e.target.value)}/>
                                </Form.Group>
                            </div>
                            <div className="col-md-4 px-1">
                                <Form.Group className='' controlId='leadMobile'>
                                    <Form.Label className='fw-bolder'>Mobile Number</Form.Label>
                                    <Form.Control className='py-2' placeholder="1234567890" onChange={(e)=>setLeadMobile(e.target.value)}/>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className="col-5 px-1">
                                <Form.Group className='' controlId='leadSource'>
                                    <Form.Label className='fw-bolder'>Source</Form.Label>
                                    <Form.Control className='py-2' placeholder="Direct Enquiry" onChange={(e)=>setLeadSource(e.target.value)}/>
                                </Form.Group>
                            </div>
                            <div className="col-7 px-1">
                                <Form.Group className='' controlId='leadMail'>
                                    <Form.Label className='fw-bolder'>Email</Form.Label>
                                    <Form.Control className='py-2' placeholder="user@example.com" onChange={(e)=>setLeadEmail(e.target.value)}/>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className="col-7 px-1">
                                <Form.Group className='' controlId='leadLocation'>
                                    <Form.Label className='fw-bolder'>Location</Form.Label>
                                    <Form.Control className='py-2' placeholder="landmark" onChange={(e)=>setLeadLocation(e.target.value)}/>
                                </Form.Group>
                            </div>
                            <div className="col-5 ">
                                <Form.Group className='' controlId='leadCourse'>
                                    <Form.Label className='fw-bolder'>Course</Form.Label>
                                    <Form.Control className='py-2' placeholder="bescrsac" onChange={(e)=>setLeadInterest(e.target.value)}/>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row staff-btn-container">
                    <div className="row col-md-6">
                        <div className="col-md-6">
                            <button type="button" onClick={handleClose} className='btn btn-outline-primary staff-btn col-12'>Cancel</button>
                        </div>
                        <div className="col-md-6">
                            <button type='submit' className='btn btn-primary staff-btn col-12'>Save</button>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default LeadAddForm