import axios from "axios";
// export const BASE_URL = 'http://127.0.0.1:8000/api/v1';
// export const MEDIA_URL = 'http://127.0.0.1:8000';

//testing server
// export const BASE_URL = 'https://lead-management-api.foxa.in/api/v1';
// export const MEDIA_URL = 'https://lead-management-api.foxa.in';

//redbeats JS3s@DOJ_ofjD986fJkdsdf
export const BASE_URL = 'https://redbeats-api.foxa.in/api/v1';
export const MEDIA_URL = 'https://redbeats-api.foxa.in';

//codeme
// export const BASE_URL = 'https://codeme-lead-api.foxa.in/api/v1';
// export const MEDIA_URL = 'https://codeme-lead-api.foxa.in';



export default axios.create({
    baseURL: BASE_URL,
    headers:{
        'Content-Type': "application/json",
        
    },
})

export const axiosPrivate=axios.create({
    baseURL: BASE_URL,
    headers:{
        'Content-Type': "application/json",
        
    },
    withCredentials:false
});