import { Modal } from "react-bootstrap"
import { BsPersonAdd } from 'react-icons/bs';
import "./Customer.css"
import CustomerAddForm from "./components/CustomerAddForm";
import { useState } from "react";
import useCustomerServices from "../../services/customerServices";
import { useEffect } from "react";
import CustomerTable from "./components/CustomerTable";

const Customer = () => {
    const [show, setShow] = useState(false)
    const [statusList, setStatusList] = useState("")
    const [customerList, setCustomerList] = useState("")
    const handleClose = () => {
        setShow(false)
    }

    const { getAllCustomers, getActiveStatus } = useCustomerServices()

    useEffect(() => {
        getCustomerList()
        getStatusList()
      }, [])
    
    const getCustomerList = async () => {
        const response = await getAllCustomers()
        if (response !== null) {
            setCustomerList(response.data)
        }
    }

    const getStatusList = async () => {
        const response = await getActiveStatus()
        if (response !== null) {
            setStatusList(response.data)
        }
    }

    return (
        // auth.role === "company" || auth.role === "agent" ?
        <div className="">
            <div className="row me-2">
                <div className="card customer-table-card">
                    <div className="card-body table-responsive">
                        <table className="table table-striped">
                            <thead className="border-4">
                                <tr className="">
                                    <th>Sl No.</th>
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>E-mail</th>
                                    <th className="pe-5">Date</th>
                                    <th className="ps-4 pe-5 text-center">Payment</th>
                                    <th className="ps-0 pe-5 text-center">Status</th>
                                </tr>
                            </thead>
                            <tbody className="customer-table">
                                <tr></tr>
                                <tr><td></td></tr>
                                {customerList && customerList.map(
                                    (data, index) => {
                                        return (
                                            <CustomerTable key={index} index={index} data={data} statusList={statusList} refresh={getCustomerList}/>
                                        )
                                    }
                                )
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="">
                        <div onClick={() => setShow(true)} className="button position-fixed add-position end-0">
                            <div className="customer-add">
                                <span  className="card mouse-pointer p-3 d-inline">
                                    <BsPersonAdd className="px-0" size="30px"/> Add Customer
                                </span>
                            </div>
                        </div>
                        <Modal
                        show={show}
                        onHide={handleClose}
                        centered={true}
                        size="lg"
                        contentClassName="lead-modal"
                        >
                        <Modal.Body className='p-5'>
                            <CustomerAddForm handleClose={handleClose} refresh={()=>getCustomerList()} />
                        </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>            
        </div>
            // :
            // <Navigate to="/" />
    )
}

export default Customer