import "./Reports.css"
import { Navigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { Menu, Tab } from 'semantic-ui-react'
import { useState } from "react"
import LeadReport from "./components/LeadReport"
import StaffReport from "./components/StaffReport"



const Reports = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const { auth } = useAuth()



  //TABS
  const panes = [
    {
      render: () => <Tab.Pane className="report-tab-pane"><LeadReport/></Tab.Pane>,
    },
    {
      render: () => <Tab.Pane  className="report-tab-pane"><StaffReport/></Tab.Pane>,
    },
  ]
  const TabMenu = ({ activeIndex, onTabChange }) => (
    <Menu className="report-tab-menu" secondary={ true} tabular>
      <Menu.Item name='Leads' className="report-tab-menu-item col-2" active={activeIndex === 0} onClick={() => onTabChange(0)} />
      <Menu.Item name='Staff'  className="report-tab-menu-item col-2" active={activeIndex === 1} onClick={() => onTabChange(1)} />
    </Menu>
  );
  
  const TabPanes = ({ panes, activeIndex }) => (
    <Tab panes={panes} activeIndex={activeIndex} />
  );

  const  handleTabChange = (index) => {
    setActiveIndex(index);
  }
  //console.log(auth)
  return (
    !auth.role === "company" || !auth.role === "agent" ?
      <Navigate to="/" />
      :
      <div className="ms-0">
        <div className="row ">
          {/* <Tab menu={{ secondary: true }} className="report-tab" panes={panes} /> */}
          <TabMenu activeIndex={activeIndex} onTabChange={handleTabChange} />
        </div>
        <div className="row report-tab-pane-container">
          <TabPanes panes={panes} activeIndex={activeIndex} />
        </div>
      </div>

  )
}

export default Reports